
import Divider from '@mui/material/Divider';
import React, { useEffect } from 'react';
import { CardTitle, Table } from "reactstrap";
import PieChart from './PieChart';
import { useTranslation } from 'react-i18next';
import TableWithBorders from '../TableWithBorders';
import {palette} from '../../assets/Palette';
import {getDataSourceLabel, getNumberWithSeparator} from '../../common/helpers/Common'

const Dashboard = (props) => {

    useEffect(() => {
        document.title = props.pageHeading; // Set the title dynamically based on props
    });
    
    const { t } = useTranslation();
    const generateDashboard = (t) => {
        let mainDiv = []
        let divList = []
        let divList2 = []
        let tableDiv = []
        let divRow = []
        let table2 = []
        let tableHead = []
        let source = process.env.REACT_APP_SOURCE

        divList.push(<CardTitle tag="h6" key='overview' style={{ color: palette.grey.byrd1, fontWeight: 'bold', fontSize: '1.0rem' }} id="overview">{t('dashboard.overview')}</CardTitle>)
        divList2.push(<CardTitle tag="h6" key='lastupdatedarticles' style={{ color: palette.grey.byrd1, fontWeight: 'bold', fontSize: '1.0rem' }} id="last-updated-items">{t('dashboard.lastUpdatedItem')}</CardTitle>)
        divList.push(<Divider key='divider1' />)
        divList2.push(<Divider key='divider2' />)
        for (let key in props.dashboardData['source']) {
            let table = []
            let sourceLabel = getDataSourceLabel(key)

            tableDiv.push(<Divider key={key + 'divider'} />)
            table.push(<tr key={key + 'source'} style={{ lineHeight: '0' }}><td key='td1'>{t('dashboard.dataProvider')}:</td><td key='td2' style={{ fontWeight: 'bold' }}>{sourceLabel}</td></tr>)
            table.push(<tr key={key + 'count'} style={{ lineHeight: '0' }}><td key='td3'>{t('dashboard.numberOfRecords')}:</td><td key='td4'>{getNumberWithSeparator(props.language,props.dashboardData['source'][key]['totalCount'])}</td></tr>)
            table.push(<tr key={key + 'base'} style={{ lineHeight: '0' }}><td key='td5'>{t('dashboard.numberOfBaseArticles')}:</td><td key='td6'>{getNumberWithSeparator(props.language,props.dashboardData['source'][key]['baseItems'])}</td></tr>)
            table.push(<tr key={key + 'manufacturer count'} style={{ lineHeight: '0' }}><td key='td7'>{t('dashboard.numberOfSupplier')}:</td><td key='td8'>{getNumberWithSeparator(props.language,props.dashboardData['source'][key]['manufacturerCount'])}</td></tr>)
            if (props.dashboardData['source'][key]['lastUpdatedDate']) {
                let updatedDate = new Date(props.dashboardData['source'][key]['lastUpdatedDate']).toLocaleString()
                table.push(<tr key={key + 'last updated date'} style={{ lineHeight: '0' }}><td key='td9'>{t('dashboard.lastUpdatedAt')}:</td><td key='td10'>{updatedDate}</td></tr>)
            }
            tableDiv.push(<Table key={key + 'table'} borderless><tbody>{table}</tbody></Table>)
        }
        
        divList.push(<div key='tableDiv'>{tableDiv}</div>)
        if (source !== 'aldi') {
            divList.push(<CardTitle tag="h6" key='lastupdatedarticles' style={{ color: palette.grey.byrd1, fontWeight: 'bold', fontSize: '1.0rem', marginTop: '5%' }} id="top-10-suppliers-with-most-items-label">{t('dashboard.top10SuppliersWithMostItems')}</CardTitle>)
            divList.push(<Divider key='divider2' />)
            if ('topManufacturers' in props.dashboardData) {
                divList.push(<PieChart key='pie' data={props.dashboardData['topManufacturers']}/>)
            }
        }
        divRow.push(<div style={{ display: 'flex', flexDirection: 'column', marginTop: '1%' }} key='row1'>{divList}</div>)
        // Overview of all Suppliers
        if (source === 'aldi') {
            divList.push(<CardTitle tag="h6" className="mt-4" key='übersicht' style={{ color: palette.grey.byrd1, fontWeight: 'bold', fontSize: '1.0rem' }} id="suppliers-overview">{t('dashboard.suppliers_overview')}</CardTitle>)
            divList.push(<Divider key='divider3' style={{marginTottom: '4px;'}} />)
            divList.push(<TableWithBorders key='pie' data={props} handleSupplierClick={props.handleSupplierClick} handleSupplierWithScopeClick={props.handleSupplierWithScopeClick} language={props.language}/>)
        }
        tableHead.push(<th key='header1' className='text-left' style={{ color: palette.grey.byrd1, fontWeight: 'bold', fontSize: '1rem' }}>GTIN</th>)
        tableHead.push(<th key='header2' className='text-left' style={{ color: palette.grey.byrd1, fontWeight: 'bold', fontSize: '1rem' }}>{t('searchResults.brand')}</th>)
        tableHead.push(<th key='header3' className='text-left' style={{ color: palette.grey.byrd1, fontWeight: 'bold', fontSize: '1rem' }}>{t('searchResults.supplier')}</th>)
        tableHead.push(<th key='header4' className='text-left' style={{ color: palette.grey.byrd1, fontWeight: 'bold', fontSize: '1rem' }}>{t('searchResults.itemDescription')}</th>)
        if (source === 'migros') {
            tableHead.push(<th key='header5' className='text-left' style={{ color: palette.grey.byrd1, fontWeight: 'bold', fontSize: '1rem' }} id="data_source">{t('searchResults.dataSource')}</th>)
        }
        if ('lastUpdatedArticles' in props.dashboardData) {
            props.dashboardData['lastUpdatedArticles'].forEach(function (d, i) {
                let articles = []
                articles.push(<td key='gtin'>{d['gtin']}</td>)
                articles.push(<td key='brand'>{d['brand']}</td>)
                articles.push(<td key='manufacturer'>{d['manufacturer']}</td>)
                articles.push(<td key='description'>{d['description']}</td>)
                if (source === 'migros') {
                    articles.push(<td key='origin'>{d['origin']}</td>)
                }
                if ('thumbnail' in d) {
                    articles.push(<td key='thumbnail'><img alt="thumbnail" style={{ maxWidth: '50%', maxHeight: '50%' }} src={'data:image/png;base64,' + d['thumbnail']} /></td>)
                }
                table2.push(<tr key={'row' + i}>{articles}</tr>)
            })
        }
        divList2.push(<Table key='table2' borderless><thead className="text-primary"><tr>{tableHead}</tr></thead><tbody>{table2}</tbody></Table>)
        divRow.push(<div key='row2' style={{ display: 'flex', flexDirection: 'column', marginLeft: '5%', marginTop: '1%' }}>{divList2}</div>)
        mainDiv.push(<div key='maindiv' style={{ display: 'flex', flexDirection: 'row' }}>{divRow}</div>)
        return mainDiv
    }

    return generateDashboard(t);

}
export default Dashboard;

