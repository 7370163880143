import React from "react";
import IconButton from '@mui/material/IconButton';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import Menu from '@mui/material/Menu';
import MenuItemComponent from './MenuItems';
import { Tooltip } from "@mui/material";
import withStyles from '@mui/styles/withStyles';

const styles = (theme) => ({
  cyanRipple: {
    '&:hover': {
      '& .MuiTouchRipple-root': {
        backgroundColor: 'rgba(0, 0, 0, 0.1)',
      },
    },
    '&:focus': {
      outlineColor: 'rgba(0, 0, 0, 0)'
    },
  },
});

class MoreButton extends React.Component {
    constructor(props) {
      super(props);
      this.state = {
        anchorEl: null,
        product: props.product,
        activeButtonId: null,
      };
    }
  
    handleClick = (event, buttonId) => {
      const { activeButtonId } = this.state;
      this.setState({
        anchorEl: event.currentTarget,
        activeButtonId: activeButtonId === buttonId ? null : buttonId, 
      });
    };

    handleClose = () => {
      this.setState({ anchorEl: null });
    };
   
    render() {
      const { anchorEl, activeButtonId } = this.state;
      const { classes } = this.props;
      const moreButtonID = 'more-button-' + this.props.recordIndex

      return (
        (<div>
          <Tooltip title={''}>
            <IconButton
              aria-controls="simple-menu"
              aria-haspopup="true"
              onClick={(event) => this.handleClick(event, "unique-id-1")}
              className={classes ? classes.cyanRipple : ''}
              size="large"
              id={moreButtonID}>
              <MoreVertIcon 
                className={activeButtonId === "unique-id-1" ? classes.activeIcon : ''}
              />
            </IconButton>
          </Tooltip>
          <Menu
            getContentAnchorEl={null}
            id="simple-menu"
            anchorEl={anchorEl}
            keepMounted
            open={Boolean(anchorEl)}
            onClose={this.handleClose}
            anchorOrigin={{ vertical: "bottom", horizontal: "left" }}
            transformOrigin={{ vertical: "top", horizontal: "left" }}
          >
            <MenuItemComponent
              product={this.props.product}
              downloadSingleCIN={this.props.downloadSingleCIN}
              downloadNestedCIN={this.props.downloadNestedCIN}
              recordIndex={this.props.recordIndex}
              downloadInformaticaXML={this.props.downloadInformaticaXML}
              toggleTradeItemHierarchyModal={this.props.toggleTradeItemHierarchyModal}
              onTradeItemHierarchyProduct={this.props.onTradeItemHierarchyProduct}
              onClose={this.handleClose}
              downloadKauflandArticlepassMoreButton={this.props.downloadKauflandArticlepassMoreButton}
              handleSnackBarOpen={this.props.handleSnackBarOpen}
              downloadOriginalMessage={this.props.downloadOriginalMessage}
              origin={this.props.origin}
            >  
            </MenuItemComponent> 
          </Menu>
        </div>)
      );
    }
  }
  
  export default withStyles(styles)(MoreButton);


