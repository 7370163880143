import React from "react";
import { CustomerContext } from "../common/CustomerContext.js";
import { withTranslation } from "react-i18next";
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";
import FormControl from '@mui/material/FormControl';

import '../assets/products.css';

import { searchQueryFilterItems, migrosSearchQueryFilterItems } from "../common/helpers/Common.js";


class SearchQueryFilter extends React.Component {
  static contextType = CustomerContext;

  constructor(props) {
    super(props);
    this.state = {
      selectedFilter: this.props.selectedFilter,
    };
  }

  componentDidMount() {}

  filterOptions() {
    const dropDownList = process.env.REACT_APP_SOURCE === 'migros' ? migrosSearchQueryFilterItems() : searchQueryFilterItems();
        
    var filters = Object.entries(dropDownList).map(([key, value]) => ({"code": key, "name": value}));

    if (["radeberger", "bc"].includes(process.env.REACT_APP_SOURCE)) {
      filters.push({ name: "Custom Attributes", code: "ca" });
    }
    return filters.map((dt, i) => {
      return (
        <MenuItem label="Select a filter" value={dt.code} key={i} name={dt.name}>
          {dt.name}
        </MenuItem>
      );
    });
  }

  render() {
    return (
      <div style={{paddingLeft: 5 }}>
        <FormControl variant="outlined" style={{width: "100%", paddingRight: "0.5rem"}}>
          <Select
            labelId="search-fields-label"
            id="search-fields-select-outlined"
            value={this.props.selectedFilter}
            onChange={this.props.onFilterChange}
            style={{ height: '47px'}}
          >
            {this.filterOptions()}
          </Select>
        </FormControl>
      </div>
    );
  }
}

export default withTranslation()(SearchQueryFilter);
