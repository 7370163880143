import React from 'react';
import i18n from '../i18n';
import Grid from '@mui/material/Grid2';
import Button from '@mui/material/Button';
import { useNavigate } from 'react-router-dom';
import ExitToAppIcon from '@mui/icons-material/ExitToApp';

const Logout = () => {
  const navigate = useNavigate();

  const handleLogout = () => {
    fetch('/products/logout/', {
        method: 'get',
        headers: {
            'Accept': 'application/json, text/plain, */*',
            'Content-Type': 'application/json'
        }
      }).then(res => {
        
      }).finally(res => {
        localStorage.removeItem('user');
        localStorage.removeItem('saved');
        navigate('/login');
    })
  };

  return (
    <Grid item>
        <Button id="logout-button" variant="contained" color="primary" startIcon={<ExitToAppIcon />} onClick={handleLogout} style={{ outline: 'none' }}>
            {i18n.t('searchResults.logOut') }
        </Button>
    </Grid>
  );
};

export default Logout;