import * as d3 from "d3";
import React from "react";

let margin = { top: 50, right: 20, bottom: 30, left: 80 },
  width = 650 - margin.left - margin.right,
  height = 350 - margin.top - margin.bottom

class PieChart extends React.Component {

  constructor(props) {
    super(props)
    this.svgRef = React.createRef()
  }

  componentDidMount() {
    this.renderSvg();
  }

  renderSvg() {
    let sortedArray = this.props.data.slice();
    sortedArray.sort(function (a, b) {
      return b[1] - a[1];
    });

    let pie = d3.pie()
      .sort(null)
    let color = d3.scaleOrdinal(d3.schemeSet3);

    let outerRadius = width / 4;
    let innerRadius = 0;
    let textPadding = outerRadius + 20;

    let arc = d3.arc()
      .innerRadius(innerRadius)
      .outerRadius(outerRadius);

    let svg = d3.select(this.svgRef.current).append("svg")
      .attr("width", width + margin.left + margin.right)
      .attr("height", height + margin.top + margin.bottom)
      .append("g").attr("transform",
        "translate(" + margin.left + "," + margin.top + ")");

    let arcs = svg.selectAll("g.arc")
      .data(pie(sortedArray.map(function (d) { return d[1] })))
      .enter()
      .append("g")
      .attr("class", "arc")
      .attr("transform", "translate(" + outerRadius + ", " + outerRadius + ")");

    arcs.append("path")
      .attr("fill", function (d, i) {
        return color(i);
      })
      .transition().delay(function (d, i) {
        return i * 150;
      }).duration(100)
      .attrTween('d', function (d) {
        var i = d3.interpolate(d.startAngle + 0.1, d.endAngle);
        return function (t) {
          d.endAngle = i(t);
          return arc(d)
        }
      });

    arcs.append("text")
      .attr("transform", function (d) {
        var c = arc.centroid(d),
          x = c[0],
          y = c[1],
          // pythagorean theorem for hypotenuse
          h = Math.sqrt(x * x + y * y);
        return "translate(" + (x / h * textPadding) + ',' +
          (y / h * textPadding) + ")";
      })
      .attr("dy", ".35em")
      .attr("text-anchor", function (d) {
        return (d.endAngle + d.startAngle) / 2 > Math.PI ?
          "end" : "start";
      })
      .style("font-size", 10)
      .text(function (d) {
        return d.value
      });

    let legendG = svg.selectAll(".legend")
      .data(pie(sortedArray.map(function (d) { return d[1] })))
      .enter().append("g")
      .attr("transform", function (d, i) {
        return "translate(" + (width - 200) + "," + (i * 15 + 20) + ")";
      })
      .attr("class", "legend");

    legendG.append("rect")
      .attr("width", 14)
      .attr("height", 14)
      .attr("fill", function (d, i) {
        return color(i);
      });

    legendG.append("text")
      .text(function (d, i) {
        return sortedArray[i][0]
      })
      .style("font-size", 10)
      .attr("y", 12)
      .attr("x", 20);
  }

  render() {
    return (
      <div ref={this.svgRef} style={this.props.style} id="pie-chart">
      </div>
    )
  }
}

export default PieChart;