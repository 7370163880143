import APIService from '../base/APIService.js';

export default class MarkantBookAPIServiceUMAPIService extends APIService {

    API_PATH = process.env.REACT_APP_MARKANT_USERMANAGEMENT_URL

    getUserRights(username) {
        return this.getWithUIToken('get-user-rights/' + username);
    }

    getUsers(){
        console.log('Getting users data...')
        return this.getWithUIToken('get-users');
    }

    getRoleRights(){
        return this.getWithUIToken('get-role-rights');
    }

    deleteUser(username){
        return this.deleteWithUIToken('delete-user/' + username);
    }

    updateUser(data){
        return this.postWithUIToken('update-user', JSON.stringify(data));
    }

    createUser(data){
        return this.postWithUIToken('create-user', JSON.stringify(data));
    }

    

}