export const ekgCodeList = {
    "BG": [
        "ekg005",
        "ekg010",
        "ekg015",
        "ekg005",
        "ekg010",
        "ekg015",
        "ekg020",
        "ekg025",
        "ekg030",
        "ekg040",
        "ekg050",
        "ekg055",
        "ekg060",
        "ekg070",
        "ekg080",
        "ekg120",
        "ekg130",
        "ekg140"
    ],
    "CZ": [
        "ekg010",
        "ekg020",
        "ekg025",
        "ekg030",
        "ekg040",
        "ekg050",
        "ekg060",
        "ekg065",
        "ekg070",
        "ekg075",
        "ekg080",
        "ekg090",
        "ekg095",
        "ekg110",
        "ekg120",
        "ekg130",
        "ekg140",
        "ekg150"
    ],
    "DE": [
        "ekg001",
        "ekg002",
        "ekg003",
        "ekg004",
        "ekg005",
        "ekg006",
        "ekg007",
        "ekg009",
        "ekg010",
        "ekg015",
        "ekg016",
        "ekg019",
        "ekg021",
        "ekg025",
        "ekg028",
        "ekg029",
        "ekg036",
        "ekg044",
        "ekg049",
        "ekg050",
        "ekg051",
        "ekg054",
        "ekg059",
        "ekg074",
        "ekg075",
        "ekg081",
    ],
    
    "HR": [
        "ekg005",
        "ekg010",
        "ekg020",
        "ekg025",
        "ekg030",
        "ekg040",
        "ekg050",
        "ekg060",
        "ekg075",
        "ekg080",
        "ekg070",
        "ekg110",
        "ekg120",
        "ekg130",
        "ekg140"
    ],
    "int": [
        "ekg008",
        "ekg011",
        "ekg024",
        "ekg026",
        "ekg031",
        "ekg032",
        "ekg034",
        "ekg035",
        "ekg037",
        "ekg038",
        "ekg039",
        "ekg048",
        "ekg055",
        "ekg056",
        "ekg058",
        "ekg061",
        "ekg062",
        "ekg064",
        "ekg069",
        "ekg071",
        "ekg090",
        "ekg120",
        "ekg121",
        "ekg122",
        "ekg123",
        "ekg124",
        "ekg125",
        "ekg126",
        "ekg127",
        "ekg128",
        "ekg129",
        "ekg130",
        "ekg131",
        "ekg132",
        "ekg133",
        "ekg068",
        "ekg134",
        "ekg135"
    ],
    "PL": [
        "ekg005",
        "ekg010",
        "ekg015",
        "ekg020",
        "ekg025",
        "ekg026",
        "ekg030",
        "ekg040",
        "ekg050",
        "ekg055",
        "ekg060",
        "ekg065",
        "ekg066",
        "ekg070",
        "ekg080",
        "ekg090",
        "ekg095",
        "ekg110",
        "ekg120",
        "ekg130",
        "ekg140",
        "ekg150",
        "ekg027"
    ],
    "RO": [
        "ekg005",
        "ekg010",
        "ekg020",
        "ekg025",
        "ekg030",
        "ekg040",
        "ekg050",
        "ekg060",
        "ekg065",
        "ekg070",
        "ekg075",
        "ekg080",
        "ekg090",
        "ekg210",
        "ekg220",
        "ekg230"
    ],
    "MD": [
        "ekg005",
        "ekg010",
        "ekg020",
        "ekg025",
        "ekg030",
        "ekg040",
        "ekg050",
        "ekg060",
        "ekg065",
        "ekg070",
        "ekg075",
        "ekg080",
        "ekg090",
        "ekg210",
        "ekg220",
        "ekg230"
    ],
    "SK": [
        "ekg200",
        "ekg210",
        "ekg220",
        "ekg225",
        "ekg230",
        "ekg240",
        "ekg250",
        "ekg260",
        "ekg270",
        "ekg275",
        "ekg290",
        "ekg310",
        "ekg320",
        "ekg330",
        "ekg340",
        "ekg350",
        "ekg211",
        "ekg221",
        "ekg231",
        "ekg241",
        "ekg251",
        "ekg261",
        "ekg271",
        "ekg276"
    ]
}