import React from "react";
import Alert from '@mui/material/Alert';

function ErrorList(props) {
    const errors = props.errors;
    const onClose = props.onClose;

    console.log(errors);
    return (
      <div>
        {errors && errors.length > 0 &&
          errors.map( (err, idx) =>
            <Alert key={idx} onClose={onClose} severity="error"><b>{err}</b></Alert>
        )}
        {!errors && <Alert onClose={onClose} severity="error">Something went wrong!</Alert>}
      </div>
    );

}
export default ErrorList;