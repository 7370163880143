import React from 'react';
import Paper from '@mui/material/Paper';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Button from '@mui/material/Button';
import { CustomerContext } from "../../../common/CustomerContext.js";
import CloseRoundedIcon from '@mui/icons-material/CloseRounded';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import { withTranslation } from 'react-i18next';
import { mapRegion} from '../../../common/helpers/RegionHelper'
import { mapLand} from '../../../common/helpers/RegionHelper';
import PubliclyPublishedProducts from "./PubliclyPublishedProducts.jsx";
import {palette} from '../../../assets/Palette';

class AddProducerPopup extends React.Component {
    static contextType = CustomerContext;


    constructor(props) {
        super(props);
        this.state = {
            isPubliclyPublishedProductsDisplayed: false,
            item: {},
            wineMakerlist: [],
            publicProducers: {},
        }

        this.linkOnClick = this.linkOnClick.bind(this);
        this.openPubliclyPublishedProductsComponent = this.openPubliclyPublishedProductsComponent.bind(this);
        this.getPublicPublishedProducts = this.getPublicPublishedProducts.bind(this);
        this.togglePubliclyPublishedProducts = this.togglePubliclyPublishedProducts.bind(this);

    }

    componentDidUpdate(){
        if(this.state.wineMakerlist !== this.props.wineMakerlist){   
            console.log("updating component")
            this.props.wineMakerlist.forEach( item => {
                this.getPublicPublishedProducts(item.winid)
            })
            this.setState({
                    wineMakerlist: this.props.wineMakerlist
            });
        }
    }

    linkOnClick(item) {
        console.log("submit button on click");
        const clientId = this.props.clientId;

        this.context.apiService.getLinkProducer(item.winid, clientId)
            .then(res => {
                console.log("Euvino Response");
                console.log(res);
                if (res.ok) {
                this.props.refreshSearchProducers();
                this.props.refreshKnownProducers();
                } else {
                        console.log(res);
                        console.log(res.errors) }
            });
    }

    getPublicPublishedProducts(winId) {
        const clientId = this.props.clientId;
        this.setState({ isLoadingComplete: false });
        this.context.apiService.getPublicPublishedProducts(winId, clientId)
            .then(res =>  {
                console.log("Euvino Response");
                console.log(res)
                if (res.ok) {
                    if(res.status === 204){
                        console.log("Euvino Response No content -", res.status)
                        this.setState({
                            publicProducers: {
                                ...this.state.publicProducers,
                                [winId]: []
                            }
                        });
                    } else {
                        res.json().then(res => {
                            if ( res.hasOwnProperty('status') && res.status === "too-many-results") {
                                res = res['preview']
                            }
                            this.setState({
                                publicProducers: {
                                    ...this.state.publicProducers,
                                    [winId]: res
                                }
                            });
                        });
                    }
                } 
            });
    }

    openPubliclyPublishedProductsComponent(item){
        console.log("try to open component, state:", this.state.publicProducers)
        this.setState({
            item: item,
            isPubliclyPublishedProductsDisplayed: true
        });
    }
    
    togglePubliclyPublishedProducts(){
        console.log("toggle PubliclyPubishedProducts")
        this.setState({
            isPubliclyPublishedProductsDisplayed: !this.state.isPubliclyPublishedProductsDisplayed
        })
    }

    render() {
        const { t } = this.props; 
        console.log("AddProducerPopup", this.props)
        console.log(this.state.item)
        return (
            (<div >
                { this.props.isSuccess ?
                        <div>
                            { this.props.wineMakerlist.length > 0 &&
                                <div>
                                    <Typography align="center">{t('euvinoModal.producerPopup')}</Typography>
                                    <IconButton
                                        aria-label="Close"
                                        className="close"
                                        data-dismiss="modal"
                                        type="button"
                                        onClick={this.props.closePopup}
                                        style={{ outline: 'none' }}
                                        size="large"
                                        id="add-producer-heading"><CloseRoundedIcon style={{ color: palette.warning.close }} />
                                    </IconButton>
                                </div>
                            }
                            {this.state.isPubliclyPublishedProductsDisplayed && 
                                <PubliclyPublishedProducts 
                                    winId={this.state.item.winid} 
                                    winmakerName={this.state.item.name} 
                                    publicProducerList={this.state.publicProducers[this.state.item.winid]}
                                    isPubliclyPublishedProductsDisplayed={this.state.isPubliclyPublishedProductsDisplayed}
                                    closePubliclyPublishedProducts={this.togglePubliclyPublishedProducts}
                                    clientId={this.props.clientId}
                            />}
                            { this.props.wineMakerlist.length > 0 &&
                                <Paper elevation={2}>
                                    <Table size="small" aria-label="a dense table">
                                        <TableHead>
                                            <TableRow>
                                                <TableCell id="add-producer-producer-heading" align="left"><b>{t('euvinoModal.producer')}</b></TableCell>
                                                <TableCell id="add-producer-win-id-heading" align="center"><b>WIN-ID</b></TableCell>
                                                <TableCell id="add-producer-region-heading" align="center"><b>{t('euvinoModal.region')}</b></TableCell>
                                                <TableCell id="add-producer-country-heading" align="center"><b>{t('euvinoModal.country')}</b></TableCell>
                                                <TableCell id="add-producer-action-heading" align="center"><b>{t('euvinoModal.action')}</b></TableCell>
                                                <TableCell id="add-producer-search-heading" align="center"><b>{t('euvinoModal.search')}</b></TableCell>
                                            </TableRow>
                                        </TableHead>
                                        <TableBody>
                                            {
                                            this.props.wineMakerlist.map(item => (
                                            <TableRow id={"add-producer-row-"+item.winid} key={item.winid}>
                                                <TableCell align="left">{item.name}</TableCell>
                                                <TableCell align="center">{item.winid }</TableCell>
                                                <TableCell align="center">{mapRegion(item.region)}</TableCell>
                                                <TableCell align="center">{mapLand(item.region)}</TableCell>
                                                <TableCell align="center">
                                                <Button
                                                    id={"add-producer-link-button-"+item.winid}
                                                    color="primary"
                                                    variant="outlined"
                                                    disabled={item.linked}
                                                    size="small"
                                                    onClick={this.linkOnClick.bind(this,item)}
                                                    >Link</Button>
                                                </TableCell>
                                                <TableCell align="center">
                                                <Button
                                                    id={"add-producer-search-button-"+item.winid}
                                                    color="primary"
                                                    variant="outlined"
                                                    size="small"
                                                    disabled={ this.state.publicProducers.hasOwnProperty(item.winid) ? !Object.keys(this.state.publicProducers[item.winid]).length > 0 : true }
                                                    onClick={this.openPubliclyPublishedProductsComponent.bind(this, item)}
                                                    >search</Button>
                                                </TableCell>
                                            </TableRow>
                                            ))}
                                        </TableBody>
                                    </Table>
                                </Paper>
                            }
                        </div>
                    :null}
            </div>)
        );
    }
}
export default withTranslation()(AddProducerPopup);
