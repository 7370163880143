import React from 'react';
import Paper from '@mui/material/Paper';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Button from '@mui/material/Button';
import { CustomerContext } from "../../../common/CustomerContext.js";
import CloseRoundedIcon from '@mui/icons-material/CloseRounded';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import { withTranslation } from 'react-i18next';
import Grid from '@mui/material/Grid';
import TextField from '@mui/material/TextField';
import MenuItem from '@mui/material/MenuItem';
import LinearProgress from '@mui/material/LinearProgress';
import Alert from '@mui/material/Alert';
import Modal from 'react-modal';
import PubliclyPublishedProducts from "./PubliclyPublishedProducts.jsx";
import {palette} from '../../../assets/Palette';
import i18next from 'i18next';

class InviteProducerPopup extends React.Component {
    static contextType = CustomerContext;

    constructor(props) {
        super(props);
        this.state = {

            wineName: '',
            bottleSize: '',
            year: '',
            wineVariety:'',
            isSubmitting: false,
            isSubmitted: false,
            isSubmissionSuccessful: false,
            beverageType:'',
            wineColor:'',
            additionaInformation:'',
            showComponent: false,
            requests: [],
            publicProducerList: [],
            refreshPublicProducts: false,
            showPublicProducts: true,
            isPubliclyPublishedProductsDisplayed: false,
            bottleLabel: i18next.t("euvinoModal.containerSize")
        };

        this.submitButtonOnClick = this.submitButtonOnClick.bind(this);
        this.handleWineName = this.handleWineName.bind(this);
        this.handleBottleSize = this.handleBottleSize.bind(this);
        this.handleYear = this.handleYear.bind(this);
        this.handleWineVariety = this.handleWineVariety.bind(this);
        this.handleClick = this.handleClick.bind(this);
        this.handleWineColor = this.handleWineColor.bind(this);
        this.handleAdditionaInformation = this.handleAdditionaInformation.bind(this);
        this.showProductsStatus = this.showProductsStatus.bind(this);
        this.getPublicPublishedProducts = this.getPublicPublishedProducts.bind(this);
        this.togglePubliclyPublishedProducts = this.togglePubliclyPublishedProducts.bind(this);
    }

    componentDidMount(){
        this.getPublicPublishedProducts(this.props.winId);
    }


    handleClick(event) {
        console.log(event.target.value);
        this.setState({ beverageType: event.target.value } , () => {
            this.bottleSizeLabel();
        })
    }

    updatePublicProducts() {
        this.setState({
            refreshPublicProducts: true
        })
    }

    togglePubliclyPublishedProducts(){
        console.log("toggle PubliclyPubishedProducts")
        this.setState({
            isPubliclyPublishedProductsDisplayed: !this.state.isPubliclyPublishedProductsDisplayed
        });
    }

    getPublicPublishedProducts(winId) {
        let clientId = this.props.clientId
        
        this.context.apiService.getPublicPublishedProducts(winId, clientId)
            .then(res =>  {
                console.log("getPublicPublishedProducts called, Euvino Response");
                console.log(res.status)
                
                if (res.ok) {
                    if(res.status === 204){
                        console.log("Euvino Response No content -", res.status)
                    } else {
                        res.json().then(res => {
                            if ( res.hasOwnProperty('status') && res.status === "too-many-results") {
                                res = res['preview']
                            }
                            this.setState({
                            publicProducerList: res
                            });
                        })
                    }
                } 
            });   
    }

    handleWineName(event) {
        this.setState({
            wineName: event.target.value
        })
    }

    handleBottleSize(event) {
        this.setState({
            bottleSize: event.target.value
        })
    }

    handleYear(event) {
        this.setState({
            year: event.target.value
        })
    }

    handleWineColor(event) {
        this.setState({
            wineColor: event.target.value
        })
    }

    handleWineVariety(event) {
        this.setState({
            wineVariety: event.target.value
        })
    }

    handleAdditionaInformation(event) {
        this.setState({
            additionaInformation: event.target.value
        })
    }

    submitButtonOnClick() {
        console.log("submit button on click");
        let clientId = this.props.clientId

        this.setState({
            isSubmitting: true,
            isSubmitted: false,
            wineName: '',
            bottleSize: '',
            year: '',
            wineVariety:'',
            beverageType:'',
            wineColor:'',
            additionaInformation:''
        })

        let producerEmail = ''
        if (process.env.REACT_APP_SOURCE === 'radeberger') {
            producerEmail = "datenmanagement@vfg-gesellschaft.de"
        } else if (process.env.REACT_APP_SOURCE === 'flaschenpost') {
            producerEmail = "pim@flaschenpost.de"
        }

        this.context.apiService.inviteProduct(this.props.winId, producerEmail, this.state.beverageType,this.state.wineName,this.state.bottleSize,this.state.year,this.state.wineColor,this.state.wineVariety, this.state.additionaInformation, clientId)
            .then(res => {
                console.log("Euvino Response");
                console.log(res);
                if (res.status===200) {
                        this.setState({
                        isSubmitting: false,
                        isSubmissionSuccessful: true,
                        isSubmitted: true,
                        });

                } else {
                        console.log(res);
                        console.log(res.errors)
                        this.setState({
                            isSubmissionSuccessful: false,
                            isSubmitting: false,
                            isSubmitted: true,
                        });
                }
            });
    }


    showProductsStatus() {
        console.log("submit Product Status button on click");
        let clientId = this.props.clientId

        this.setState({
            showComponent: true
        })

        this.context.apiService.fetchProductRequests(this.props.winId, clientId)
            .then(res => {
                console.log("Euvino Response");
                console.log(res);
                if (res.ok) {
                    res.json().then(res => {
                        this.setState({
                        requests: res,
                        });
                });
                } else {
                        console.log(res);
                        console.log(res.errors)
                }
            });
    }

    bottleSizeLabel(){
        const { t } = this.props;

        if (this.state.beverageType === t('euvinoModal.beverageActiveLabel3') || 
                    this.state.beverageType === t('euvinoModal.beverageActiveLabel4')) {
            this.setState({ bottleLabel: t('euvinoModal.bottleSize') })
        }
    }

    render() {
    const { t } = this.props;
    return (
        (<Modal
                isOpen={this.props.rowClickValue}
                ariaHideApp={false}
                >
            <div className='popup'>
            <div className='popup\_inner'>
                    <div style={{ paddingTop: '4em' }}>
                        <IconButton
                            aria-label="Close"
                            className="close"
                            data-dismiss="modal"
                            type="button"
                            onClick={this.props.closePopup}
                            style={{ outline: 'none' }}
                            size="large"><CloseRoundedIcon style={{ color: palette.warning.close }} />
                        </IconButton>
                        <h2>{t('euvinoModal.informationAboutProducer')} {this.props.winmakerName}</h2>
                        {this.state.isPubliclyPublishedProductsDisplayed &&
                            <PubliclyPublishedProducts 
                            winId={this.props.winId} 
                            winmakerName={this.props.winmakerName} 
                            publicProducerList={this.state.publicProducerList}
                            refreshPublicProducts={this.state.refreshPublicProducts}
                            isPubliclyPublishedProductsDisplayed={this.state.isPubliclyPublishedProductsDisplayed}
                            closePubliclyPublishedProducts={this.togglePubliclyPublishedProducts}
                            clientId={this.props.clientId}
                            /> }
                        <hr />
                        <Typography align="left"><b>{t('euvinoModal.inviteProduct')}</b></Typography>
                        <br />
                        <Grid container direction="row" justifyContent="flex-start">
                            <Grid item>
                                <Paper elevation={1}>
                                    <Table size="small" aria-label="a dense table">
                                        <TableBody>
                                            <TableRow >
                                                <TableCell  align="center">{this.props.winId}</TableCell>
                                                <TableCell  align="center">{this.props.winmakerName}</TableCell>
                                                <TableCell  align="center"><a href={"mailto:"+this.props.winmakerAddress}>{this.props.winmakerAddress}</a></TableCell>
                                            </TableRow>
                                        </TableBody>
                                    </Table>
                                </Paper>
                            </Grid>
                        </Grid>
                        <br />
                        <br />
                        <Grid container direction="row" justifyContent="flex-start" spacing={4}>
                            <Grid item>
                                    <TextField
                                        label={t('euvinoModal.beverage')}
                                        id="beverage"
                                        value={this.state.beverageType}
                                        onChange={this.handleClick}
                                        select variant="outlined" size="small"
                                        style={{ width: '240px'}}
                                    >
                                        <MenuItem value={t('euvinoModal.beverageActiveLabel1')}>{t('euvinoModal.beverageActiveLabel1')}</MenuItem>
                                        <MenuItem value={t('euvinoModal.beverageActiveLabel2')}>{t('euvinoModal.beverageActiveLabel2')}</MenuItem>
                                        <MenuItem value={t('euvinoModal.beverageActiveLabel3')}>{t('euvinoModal.beverageActiveLabel3')}</MenuItem>
                                        <MenuItem value={t('euvinoModal.beverageActiveLabel4')}>{t('euvinoModal.beverageActiveLabel4')}</MenuItem>
                                        <MenuItem value={t('euvinoModal.beverageActiveLabel5')}>{t('euvinoModal.beverageActiveLabel5')}</MenuItem>
                                        {process.env.REACT_APP_SOURCE === 'flaschenpost' ?
                                            <MenuItem value={t('euvinoModal.beverageActiveLabel6')}>{t('euvinoModal.beverageActiveLabel6')}</MenuItem>
                                        :<MenuItem value={t('euvinoModal.beverageActiveLabel7')}>{t('euvinoModal.beverageActiveLabel7')}</MenuItem>}
                                    </TextField>

                            </Grid>
                            <Grid item>
                                    <TextField
                                        label={t('euvinoModal.produktTitle')}
                                        id="wineName"
                                        value={this.state.wineName}
                                        onChange={this.handleWineName}
                                        variant="outlined" size="small"
                                        style={{ width: '240px' }}
                                    >
                                    </TextField>
                            </Grid>
                            {this.state.beverageType !== t('euvinoModal.beverageActiveLabel6') ?
                                <Grid item>
                                        <TextField
                                            label={this.state.bottleLabel}
                                            id="bottleSize"
                                            value={this.state.bottleSize}
                                            onChange={this.handleBottleSize}
                                            variant="outlined" size="small"
                                            style={{ width: '240px' }}
                                        >
                                    </TextField>
                                </Grid>:null}
                            <Grid item >
                                    <TextField
                                        label={this.state.beverageType === t('euvinoModal.beverageActiveLabel3') 
                                            || this.state.beverageType === t('euvinoModal.beverageActiveLabel4') ?
                                        t('euvinoModal.otherInformation1'):
                                        this.state.beverageType === t('euvinoModal.beverageActiveLabel2') ? t('euvinoModal.otherInformation2'):
                                        this.state.beverageType === t('euvinoModal.beverageActiveLabel5') ? t('euvinoModal.otherInformation3'):
                                        this.state.beverageType === t('euvinoModal.beverageActiveLabel6') ? t('euvinoModal.otherInformation3'):
                                        t('euvinoModal.otherInformation4')}
                                        id="additionaInformation"
                                        value={this.state.additionaInformation}
                                        onChange={this.handleAdditionaInformation}
                                        variant="outlined" size="small"
                                        style={{ width: '520px' }}
                                    >
                                    </TextField>
                            </Grid>
                        </Grid>
                        <br/>
                        <Grid container direction="row" justifyContent="flex-start">
                            {this.state.beverageType === t('euvinoModal.beverageActiveLabel3') ||
                             this.state.beverageType === t('euvinoModal.beverageActiveLabel4') ?
                            <div className='row'  style={{ padding: '1em'}}>
                                <Grid item>
                                    <TextField
                                        label={t('euvinoModal.year')}
                                        id="year"
                                        value={this.state.year}
                                        onChange={this.handleYear}
                                        variant="outlined" size="small"
                                        style={{ width: '180px' }}
                                    >
                                     </TextField>
                                     </Grid>
                                     <br/>
                                     <Grid item>
                                    <TextField
                                        label={t('euvinoModal.grapeVariety')}
                                        id="wineVariety"
                                        value={this.state.wineVariety}
                                        onChange={this.handleWineVariety}
                                        variant="outlined" size="small"
                                        style={{ width: '180px' }}
                                    >
                                    </TextField>
                                    </Grid>
                                    <Grid item>
                                    <TextField
                                        label={t('euvinoModal.wineColor')}
                                        id="handleWineColor"
                                        value={this.state.handleWineColor}
                                        onChange={this.handleWineColor}
                                        variant="outlined" size="small"
                                        style={{ width: '180px' }}
                                    >
                                    </TextField>
                                    </Grid>
                            </div>:null}
                        </Grid>
                        <br/>
                        <Grid container direction="row" justifyContent="flex-start">
                            <Grid item >
                                <Button variant="contained"
                                        color="primary"
                                        onClick={this.submitButtonOnClick}
                                >
                                {t('euvinoModal.productSubmit')}
                                </Button>
                            </Grid>
                            <Grid item xs={12}>
                                <div><br />
                                    {this.state.isSubmitting && <div><br /><LinearProgress /></div>}
                                    {this.state.isSubmitted ?
                                        !this.state.isSubmissionSuccessful ?
                                             <Alert severity="error">{t('euvinoModal.newInviteProductErrorMessage')}</Alert>
                                            : <Alert severity="success">{t('euvinoModal.newInviteProductSuccessMessage')}</Alert>
                                        : null
                                    }
                                </div>
                            </Grid>
                        </Grid>
                        <hr />
                        <br />
                        <Grid container direction="row" justifyContent="flex-start" spacing={4}>
                            <Grid item xs={6}>
                                <Typography align="left"><b>{t('euvinoModal.productRequestsStatus')}</b></Typography>
                                <br />
                                <Button variant="contained"
                                        color="primary"
                                        alignItems="center"
                                        style={{ width: '520px'}}
                                        onClick={this.showProductsStatus}
                                    >
                                        {t('euvinoModal.showSentProductEnquiries')}
                                </Button>
                            { this.state.showComponent ?
                                <Paper elevation={2}>
                                    <Table size="small" aria-label="a dense table">
                                            <TableHead>
                                                <TableRow>
                                                    <TableCell align="left"><b>{t('euvinoModal.internalIdentifier')}</b></TableCell>
                                                    <TableCell align="left"><b>{t('euvinoModal.searchStringSentToTheProducer')}</b></TableCell>
                                                    <TableCell align="left"><b>{t('euvinoModal.statusOfTheRequest')}</b></TableCell>
                                                </TableRow>
                                            </TableHead>
                                            <TableBody>
                                            {
                                            Object.keys(this.state.requests).map((item,i) => (
                                            <TableRow >
                                                <TableCell align="left">{this.state.requests[i][0]}</TableCell>
                                                <TableCell align="left">{this.state.requests[i][1]}</TableCell>
                                                <TableCell align="left">{this.state.requests[i][3] ? 
                                                    t('euvinoModal.answered') : t('euvinoModal.open') }
                                                </TableCell>
                                            </TableRow>
                                            ))}
                                        </TableBody>
                                    </Table>
                                </Paper>
                            :null}
                            </Grid>
                    </Grid>
                    </div>
                    <br/>
                    <hr /><br/>
                    {this.state.publicProducerList.length > 0 && 
                    <div>
                        <Grid>
                            <Typography align="left"><b>{t('euvinoModal.showPubliclyPublishedProducts')}</b></Typography>
                            <br/>
                            <Button 
                            variant="contained"
                            color="primary"
                            onClick={ () => {this.togglePubliclyPublishedProducts() }}
                            >{t('euvinoModal.showPubliclyPublishedProductsFrom') + ' ' + this.props.winmakerName}
                            </Button>
                        </Grid>
                    </div>
                    }
            </div>
            </div>
        </Modal>)
    );
    }
}
export default withTranslation()(InviteProducerPopup);
