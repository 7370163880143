import React from "react";
import { withTranslation } from 'react-i18next';
import { CustomerContext } from "../../../common/CustomerContext.js";
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import Typography from '@mui/material/Typography';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import Paper from '@mui/material/Paper';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import InviteProducerPopup from './InviteProducerPopup';
import { mapRegion} from '../../../common/helpers/RegionHelper'
import { mapLand} from '../../../common/helpers/RegionHelper'
import CircularProgress from '@mui/material/CircularProgress';
import {palette} from '../../../assets/Palette';


class KnownProducers extends React.Component {
    static contextType = CustomerContext;

    constructor(props) {
        super(props);
        this.state = {
            wineId: '',
            winmakerName: '',
            winmakerAddress: '',
            rowClick:false,
            refreshProducerList: true
        };

        this.openOnRowClick = this.openOnRowClick.bind(this);
        this.closeOnClick = this.closeOnClick.bind(this);
    }


    closeOnClick() {
        console.log("Close button on click");
        this.setState({
            rowClick: !this.state.rowClick
        });
    }

    openOnRowClick(event,wineid,winmakername, winmakeraddress) {
        event.preventDefault();
        this.setState({
            wineId: wineid,
            winmakerName: winmakername,
            winmakerAddress: winmakeraddress,
            rowClick: !this.state.rowClick
        });
    }

    render() {
        const { t } = this.props;
        return <div>
            <Accordion elevation={1}>
                <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="panel1a-content"
                    id="known-producer-header">    
                    <Typography><b>{t('euvinoModal.producerInformation')}</b></Typography>
                </AccordionSummary>
                <AccordionDetails>
                    <div style={{ paddingTop: '1em', cursor: 'pointer'}}>
                        { (!this.props.showLoadingIcon && this.props.winemakerList.length > 0) ? 
                            <Paper elevation={2}>
                                <Table size="small" aria-label="a   dense table">
                                    <TableHead>
                                        <TableRow>
                                            <TableCell id="known-producer-win-id-heading" align="center"><b>WIN-ID</b></TableCell>
                                            <TableCell id="known-producer-producer-name-heading" align="center"><b>{t('euvinoModal.producerName')}</b></TableCell>
                                            <TableCell id="known-producer-email-heading" align="left"><b>{t('euvinoModal.emailAddressProducer')}</b></TableCell>
                                            <TableCell id="known-producer-region-heading" align="center"><b>{t('euvinoModal.region')}</b></TableCell>
                                            <TableCell aid="known-producer-country-heading" lign="center"><b>{t('euvinoModal.country')}</b></TableCell>
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        {this.props.winemakerList.map(item => (
                                        <TableRow hover id={"known-producer-row-"+item.winid} key={item.winid} onClick={(event)=>this.openOnRowClick(event,item.winid,item.name,item.email) }>
                                            <TableCell align="center">{item.winid}</TableCell>
                                            <TableCell align="left">{item.name }</TableCell>
                                            <TableCell align="left">{item.email }</TableCell>
                                            <TableCell align="center">{mapRegion(item.region)}</TableCell>
                                            <TableCell align="center">{mapLand(item.region)}</TableCell>
                                        </TableRow>
                                    ))}
                                    {this.state.rowClick? <InviteProducerPopup clientId={this.props.clientId} rowClickValue={this.state.wineId} closePopup={this.closeOnClick} winId={this.state.wineId} winmakerName={this.state.winmakerName} winmakerAddress={this.state.winmakerAddress}/>:null}
                                    </TableBody>

                                </Table>
                            </Paper> :
                            <div style={{paddingLeft: "10px"}}>
                                <CircularProgress size={30} style={{ color: palette.secondary.main }} thickness={4} />
                            </div>
                        }
                    </div>
                </AccordionDetails>
            </Accordion>
        </div>;
    }

}
export default withTranslation()(KnownProducers);
