const iso = require('iso-3166-1');

export function getCountryName(targetMarketCountryCode) {
    if (targetMarketCountryCode === '840') {
        return "United States";
    } else {
        const tm = iso.whereNumeric(targetMarketCountryCode);
        if (tm) {
            return tm["country"];
        } else {
            return "";
        }
    }
}

export function getCountryNameWithCode(targetMarketCountryCode, language='en') {
    let countryName = ''

    if (targetMarketCountryCode === 'all') {
        return "All"
    }

    if (language === 'de') {
        countryName = getDeCountryNameWithCode(targetMarketCountryCode);
    } else {
        countryName = getCountryName(targetMarketCountryCode);
    }
    
    if (countryName) {
        return countryName + " (" + targetMarketCountryCode + ")";
    } else {
        return targetMarketCountryCode;
    }
}

export function getDeCountryNameWithCode(targetMarketCountryCode){
    const codeList = {
        '276': 'Deutschland',
        '040': 'Österreich',
        '840': 'USA',
        '036': 'Australien',
        '826': 'Vereinigtes Königreich',
        '372': 'Irland',
        '348': 'Ungarn',
        '705': 'Slowenien',
        '380': 'Italien',
        '756': 'Schweiz',
        '616': 'Polen',
        '203': 'Tschechische Republik',
        '100': 'Bulgarien',
        '703': 'Slowakei',
        '642': 'Rumänien',
        '498': 'Republik Moldau',
        '191': 'Kroatien'
    }

    return (codeList[targetMarketCountryCode] ? codeList[targetMarketCountryCode] : '')
}
