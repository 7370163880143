
import IconButton from '@mui/material/IconButton';
import CloseRoundedIcon from '@mui/icons-material/CloseRounded';
import React from "react";
import i18next from 'i18next';
import Modal from 'react-modal';
import Input from '@mui/material/Input';
import { withTranslation } from 'react-i18next';
import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';
import Grid from '@mui/material/Grid2';
import FormControl from '@mui/material/FormControl';
import InputAdornment from '@mui/material/InputAdornment';
import EmailIcon from '@mui/icons-material/Email';
import DeleteIcon from '@mui/icons-material/Delete';
import GetAppIcon from '@mui/icons-material/GetApp';
import Tooltip from '@mui/material/Tooltip';
import { red } from '@mui/material/colors';
import { saveAs } from "file-saver";
import MuiAlert from '@mui/material/Alert';
import { getCookie } from '../../common/helpers/Common';
import {palette} from '../../assets/Palette';
import ExcelFileUpload from '../ExcelFileUpload'
import AppBar from '@mui/material/AppBar';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Box from '@mui/material/Box';
import { CustomerContext } from "../../common/CustomerContext.js";
import { ReactComponent as DataImportIcon } from '../../database-import.svg';

import ArticlePassExportList from '../markant-data-request/ArticlePassExportList.jsx'
import ArticlePassRequest from '../markant-data-request/ArticlePassRequest';

function Alert(props) {
    return <MuiAlert elevation={6} variant="filled" {...props} />;
}

const emailCountries = ['BG','CZ','DE','HR','PL','RO','MD','SK']

const countryEmailIds = {
    'BG': 'pim.support@bg.markant.com',
    'CZ': 'pim.support@cz.markant.com',
    'DE': 'pim.support@de.markant.com',
    'HR': 'pim.support@hr.markant.com',
    'PL': 'pim.support@pl.markant.com',
    'RO': 'pim.support@ro.markant.com',
    'MD': 'pim.support@ro.markant.com',
    'SK': 'pim.support@sk.markant.com'
}

class DataRequestModal extends React.Component {
    static contextType = CustomerContext;

    constructor(props) {
        super(props);
        this.state = {
            value: this.props.countryCode,
            inputValue: '',
            email: '',
            requesterEmail: getCookie('user_mail').replace(/"/g, ''),
            itemDetails: this.props.selectedItems,
            countryEkgCodeList: [],
            countryCode: this.props.countryCode,
            emailIconDisable: false,
            emailIconColor: 'primary',
            valueTab: 0,
            supplierMasterData: ''
        }

        this.exportMarkantData = this.exportMarkantData.bind(this);
        this.importToApassRequest = this.importToApassRequest.bind(this);
        this.sendEmail = this.sendEmail.bind(this);
        this.updateItemDetailsState = this.updateItemDetailsState.bind(this);
        this.updateCountryCodeChange = this.updateCountryCodeChange.bind(this);
        this.updateSupplierMasterData = this.updateSupplierMasterData.bind(this);
        this.updateRequesterEmailChange = this.updateRequesterEmailChange.bind(this);
        this.updateExcelUploadError = this.updateExcelUploadError.bind(this);
    }

    UNSAFE_componentWillReceiveProps(nextProps) {
        const { selectedItems } = nextProps;
        if(selectedItems !== this.state.itemDetails) {
            this.setState({itemDetails: selectedItems});
        }
    }

    componentDidUpdate(prevProps) {
        if (prevProps.countryCode !== this.props.countryCode) {
          this.setState({ value: this.props.countryCode });
          this.setState({ email: countryEmailIds[this.props.countryCode] });
          this.setState({ countryEkgCodeList: this.props.ekgCodeList[this.props.countryCode] });
        }
    }

    renderEmailData(t) {
        let gpcClassification = []
        if (this.props.toggleDataRequestModal) {
            gpcClassification.push(<h2 key={'h1'} id="render-email-data-request-heading">Markant {t('markantDataRequestModal.dataRequestModalHeading')}</h2>)
        }
        return gpcClassification
    }

    handleEmailChange = (prop) => (event) => {
        this.setState({email: event.target.value})
    };

    handleRequesterEmailChange = (prop) => (event) => {
        this.setState({requesterEmail: event.target.value})
    };

    handleSupplierMasterDataChange = (prop) => (event) => {
        this.setState({supplierMasterData: event.target.value})
    };

    handleFormChange = (i) => (event) => {
        const { name, value } = event.target;
        const itemDetails = [...this.state.itemDetails];

        if (event.target.tagName === 'LI') {
            itemDetails[i]['ekg'] =  event.target.innerText;
        } else {
            itemDetails[i][name] = value;
        }
        this.setState({itemDetails});
    };

    handleAddItem = () => {
        const item = {
            gln: '',
            tax_number: '',
            partner_name: '',
            gtin: '',
            description: '',
            article_type: '',
            ekg: ''
        };

        this.setState({
            itemDetails: [...this.state.itemDetails, item]
        });
    };

    handleRemoveSpecificRow = (i) => () => {
        const itemDetails = [...this.state.itemDetails]
        itemDetails.splice(i, 1)
        this.setState({ itemDetails })
    };

    validateForm(itemDetails, requesterEmail) {
        let gln = itemDetails.filter(item => item.gln === "")
        let gtin = itemDetails.filter(item => item.gtin === "")
        let ekg = itemDetails.filter(item => item.ekg === "")

        if (gln.length > 0 || gtin.length > 0 || ekg.length > 0 ||  requesterEmail === "") {
            return false
        }

        return true
    }

    validateAddApassRequestForm(itemDetails) {
        let gln = itemDetails.filter(item => item.gln === "")
        let gtin = itemDetails.filter(item => item.gtin === "")
        let ekg = itemDetails.filter(item => item.ekg === "")

        if (gln.length > 0 || gtin.length > 0 || ekg.length > 0) {
            return false
        }

        return true
    }

    exportMarkantData(event) {
        let fileName = ''
        let itemDetails = this.state.itemDetails
        let requesterEmail = this.state.requesterEmail
        let countryCode = this.state.value
        let supplierMasterData = this.state.supplierMasterData

        if(this.validateForm(itemDetails, requesterEmail)) {

            this.props.apiService.saveData(itemDetails, countryCode)
                .catch(err => console.error(err));

            this.props.apiService.exportMarkantDataToExcel(itemDetails, requesterEmail, countryCode, supplierMasterData, this.props.language)
                .then(res => {
                    let contentDisposition = res.headers.get('content-disposition').split(';')[1]
                    fileName = contentDisposition.split('=')[1]
                    return res.blob();
                }).then(blob => {
                    if (blob !== undefined) {
                        if (blob instanceof Blob) {
                            saveAs(blob, fileName);
                        } else if ('responseDescription' in blob) {
                            alert(blob['responseDescription'])
                        }
                    }
                }).catch(err => console.error(err));
        } else {
            const snackBarMessage = i18next.t("markantDataRequestModal.allMandatoryFieldsRequired")
            this.props.handleSnackBarOpen(snackBarMessage, 'error')

            return false
        }
    }

    importToApassRequest(event) {
        let itemDetails = this.state.itemDetails
        let countryCode = this.state.value

        if(this.validateAddApassRequestForm(itemDetails)) {
            this.props.apiService.saveData(itemDetails, countryCode)
                .then(res => {
                    if (res.status === 200) {
                        const snackBarMessage = i18next.t("markantDataRequestModal.importToApassRequest")
                        this.props.handleSnackBarOpen(snackBarMessage)
                    } else if (res.status === 202){
                        const snackBarMessage = i18next.t("markantDataRequestModal.noArticlePassRequestsAdded")
                        this.props.handleSnackBarOpen(snackBarMessage, 'error')
                    }
                })
                .catch(err => console.error(err));
        } else {
            const snackBarMessage = i18next.t("markantDataRequestModal.allMandatoryFieldsRequired")
            this.props.handleSnackBarOpen(snackBarMessage, 'error')
            return false
        }
    }

    sendEmail(event) {
        let itemDetails = this.state.itemDetails
        let requesterEmail = this.state.requesterEmail
        let mailTo = this.state.email
        let countryCode = this.state.value
        let supplierMasterData = this.state.supplierMasterData

        if(this.validateForm(itemDetails, requesterEmail)) {
            this.setState({emailIconDisable: true})
            this.setState({emailIconColor: ''})

            this.props.apiService.saveData(itemDetails, countryCode)
                .catch(err => console.error(err));

            this.props.apiService.sendEmail(itemDetails, requesterEmail, countryCode, mailTo, supplierMasterData, this.props.language)
                .then(res => {
                    return res.json()
                }).then(res => {
                    if (res["status_code"] === "200") {
                        const snackBarMessage = i18next.t("markantDataRequestModal.emailSentSuccessfully")
                        this.props.handleSnackBarOpen(snackBarMessage)
                    } else {
                        const snackBarMessage = i18next.t("markantDataRequestModal.errorSendingEmail")
                        this.props.handleSnackBarOpen(snackBarMessage, 'error')
                    }

                    this.setState({emailIconDisable: false})
                    this.setState({emailIconColor: 'primary'})

                }).catch(err => console.error(err));
        } else {
            const snackBarMessage = i18next.t("markantDataRequestModal.allMandatoryFieldsRequired")
            this.props.handleSnackBarOpen(snackBarMessage, 'error')

            return false
        }
    }
    
    calculateLabelWidth(text, addPadding) {
        const canvas = document.createElement('canvas')
        const context = canvas.getContext('2d')
        context.font = '14px Arial'
        const width = context.measureText(text).width + addPadding
        return width
    }

    updateItemDetailsState(itemDetails) {
        this.setState({ itemDetails: itemDetails })
    }

    updateCountryCodeChange (countryCode){
        this.setState({value: countryCode})
    };

    updateSupplierMasterData (supplierMasterData){
        this.setState({supplierMasterData: supplierMasterData})
    };

    updateRequesterEmailChange(requesterEmail) {
        this.setState({requesterEmail: requesterEmail})
    };

    updateExcelUploadError() {
        const snackBarMessage = "Error while uploading the file"
        this.props.handleSnackBarOpen(snackBarMessage, 'error')
    };

    a11yProps(index) {
        return {
          'aria-controls': `simple-tabpanel-${index}`,
        };
    }

    handleTabChange = (event, newValue) => {
        this.setState({valueTab: newValue})
    }


    render() {
        const { t } = this.props;
        return (
            <Modal
                isOpen={this.props.toggleDataRequestModal}
                style={{ overlay: { zIndex: 1000 } }}
                ariaHideApp={false}
            >
                <div style={{ display: 'flex', flexDirection: 'row', position: 'fixed', right: '4%', margionTop: '-15px' }}>
                    <Grid container>
                        
                        <Grid item  style={{paddingTop: "11px"}}>
                            <Tooltip title={t('markantDataRequestModal.close')}> 
                                <IconButton
                                    aria-label="Close"
                                    className="close"
                                    data-dismiss="modal"
                                    type="button"
                                    onClick={this.props.handleDataRequestModalClose}
                                    style={{ outline: 'none',  marginRight: '8%'}}
                                    size="large">
                                    <CloseRoundedIcon style={{ color: palette.warning.close }} />
                                </IconButton>
                            </Tooltip>  
                        </Grid>
                    </Grid>
                </div>
                <div style={{paddingLeft: '16px'}}>
                    <h2 key={'h1'} id="data-request-modal-heading">Markant {t('markantDataRequestModal.dataRequestModalHeading')}</h2>
                    <Box sx={{ bgcolor: 'background.paper', width: "100%", letterSpacing: "0.02857em !important" }}>
                        <AppBar position="static">
                            <Tabs 
                                value={this.state.valueTab} 
                                onChange={this.handleTabChange} 
                                aria-label={t('markantDataRequestModal.dataRequestModalHeading')}
                                indicatorColor="secondary"
                                textColor="inherit"
                                >
                                <Tab 
                                    label={t('markantDataRequestModal.dataRequestModalHeading')} 
                                    {...this.a11yProps(0)} 
                                    sx={{
                                        '&:focus': {
                                            outline: 'none',
                                        },
                                    }}
                                    id={"data-request-tab"}
                                />
                                <Tab 
                                    label={t('markantDataRequestModal.apassRequest')} 
                                    {...this.a11yProps(1)} 
                                    sx={{
                                        '&:focus': {
                                            outline: 'none',
                                        },
                                    }}
                                    id={"article-pass-request-tab"}
                                />
                                <Tab 
                                    label={t('markantDataRequestModal.apassExportList')}
                                    {...this.a11yProps(2)} 
                                    sx={{
                                        '&:focus': {
                                            outline: 'none',
                                        },
                                    }}
                                    id={"article-pass-export-list-tab"}
                                />
                            </Tabs>
                        </AppBar>
                        <TabPanel value={this.state.valueTab} index={0}>
                            <div style={{marginTop: '10px'}}>
                                <Grid container spacing={1}>
                                    <Grid size={2} style={{paddingTop: "8px"}}>
                                        <Autocomplete
                                            value={this.state.value}
                                            onChange={(event, newValue) => {
                                                this.setState({value: newValue})
                                                this.setState({email: countryEmailIds[newValue]})
                                                this.setState({countryEkgCodeList: this.props.ekgCodeList[newValue]})
                                            }}
                                            inputValue={this.state.inputValue}
                                            onInputChange={(event, newInputValue) => {
                                                this.setState({inputValue: newInputValue})
                                            }}
                                            id="data-request-country-code-drop-down-list"
                                            options={emailCountries}
                                            style={{ width: '100%' }}
                                            renderInput={(params) => 
                                                <TextField 
                                                    {...params} 
                                                    label={t('markantDataRequestModal.countryCode')} 
                                                    variant="outlined"
                                                    sx={{
                                                        '& .MuiInputLabel-root': {
                                                          width: '100%',
                                                          marginTop: '-7px',
                                                        },
                                                        '& .MuiInputLabel-shrink': {
                                                            marginTop: '0px',
                                                        },
                                                      }}
                                                />
                                            }
                                            
                                        />
                                    </Grid>
                                    <Grid size={3} style={{paddingLeft: "15px"}}>
                                        <TextField
                                            label={t('markantDataRequestModal.mailTo')}
                                            value={this.state.email}
                                            onChange={this.handleEmailChange('email')}
                                            id="data-request-mail-to-text-box"
                                            sx={{ m: 1, width: '25ch' }}
                                            slotProps={{
                                                input: {
                                                startAdornment: <InputAdornment position="start"><EmailIcon /></InputAdornment>,
                                                },
                                            }}
                                            style={{ width: '100%' }}
                                        />
                                    </Grid>
                                    <Grid size={3} style={{paddingLeft: "15px"}}>
                                        <TextField
                                            required
                                            label={t('markantDataRequestModal.requestorEmail')}
                                            value={this.state.requesterEmail}
                                            onChange={this.handleRequesterEmailChange('requesterEmail')}
                                            id="data-request-requester-email-text-box"
                                            sx={{ 
                                                m: 1, 
                                                width: '25ch',
                                                '& .MuiInputLabel-root': {
                                                    color: 'red', 
                                                },
                                                '& .MuiInputLabel-root.Mui-focused': {
                                                    color: 'red',
                                                },
                                            }}
                                            slotProps={{
                                                input: {
                                                startAdornment: <InputAdornment position="start"><EmailIcon /></InputAdornment>,
                                                },
                                            }}
                                            style={{ width: '98%' }}
                                        />
                                    </Grid>
                                    <Grid size={4} style={{paddingLeft: "15px"}}>
                                        <Grid container>
                                            <Grid size={1} style={{paddingLeft: "15px", marginTop: "-4px"}}>
                                                <ExcelFileUpload key='excel-file-upload' 
                                                    apiService={this.props.apiService} 
                                                    updateItemDetailsState={this.updateItemDetailsState} 
                                                    updateCountryCodeChange={this.updateCountryCodeChange} 
                                                    updateRequesterEmailChange={this.updateRequesterEmailChange}
                                                    updateExcelUploadError={this.updateExcelUploadError}
                                                    updateSupplierMasterData={this.updateSupplierMasterData}/>
                                            </Grid>
                                            <Grid size={1} style={{marginTop: "-4px"}}>
                                                <Tooltip title={t('markantDataRequestModal.sendEmail')} aria-label={t('markantDataRequestModal.sendEmail')}>
                                                    <IconButton
                                                        disabled={this.state.emailIconDisable}
                                                        onClick={this.sendEmail}
                                                        size="large">
                                                        <EmailIcon color={this.state.emailIconColor} style={{cursor: "pointer", marginRight: '8%'}} id="data-request-email-icon"/>
                                                    </IconButton>
                                                </Tooltip>
                                            </Grid>
                                            <Grid size={1} style={{marginTop: "-4px"}}>
                                                <Tooltip title={t('markantDataRequestModal.excelDownload')} aria-label={t('markantDataRequestModal.excelDownload')}>
                                                    <IconButton
                                                        disabled={false}
                                                        color="primary"
                                                        onClick={this.exportMarkantData}
                                                        size="large"
                                                        id="data-request-excel-file-download-icon">
                                                        <GetAppIcon color="primary" style={{cursor: "pointer", marginRight: '8%'}}/>
                                                    </IconButton>
                                                </Tooltip>
                                            </Grid>
                                            <Grid size={1} style={{marginTop: "-7px"}}>
                                                <Tooltip title={t('markantDataRequestModal.addToApassRequest')} aria-label={t('markantDataRequestModal.addToApassRequest')}>
                                                    <IconButton
                                                        disabled={false}
                                                        color="primary"
                                                        onClick={this.importToApassRequest}
                                                        size="large"
                                                        id="data-request-import-to-apass-request-icon">
                                                        <DataImportIcon width={27} height={27} color="primary" style={{cursor: "pointer", marginRight: '8%', fontSize: 30}}/>
                                                    </IconButton>
                                                </Tooltip>
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </div>
                            <div>
                                <Grid container>
                                    <Grid size={8}>
                                        <FormControl fullWidth variant="outlined">
                                            <TextField
                                                id="data-request-supplier-master-data-text-box"
                                                label={t('markantDataRequestModal.supplierMasterData')}
                                                style={{ width: '100%' }}
                                                margin="normal"
                                                InputLabelProps={{
                                                    shrink: true,
                                                }}
                                                variant="outlined"
                                                value={this.state.supplierMasterData}
                                                onChange={this.handleSupplierMasterDataChange('supplierMasterData')}
                                                labelWidth={this.calculateLabelWidth(t('markantDataRequestModal.requestorEmail'), 35)}
                                            />
                                        </FormControl>
                                    </Grid>
                                </Grid>
                            </div>

                            <div style={{marginTop: '26px', fontSize: "15px"}}>
                                <div style={{color: 'red'}} id="data-request-mandatory-field-text">* {t('markantDataRequestModal.mandatoryField')}</div>
                                <div style={{marginTop: '6px'}} id="data-request-gln-text">GLN = Global Location Number</div>
                            </div>
                            <div style={{marginTop: '16px'}}>
                                <Grid container spacing={1} style={{fontSize: "15px", fontWeight: 'bold'}}>
                                    <Grid size={2} style={{ backgroundColor: palette.text.veryLight, marginRight: '8px'}}>
                                        <div key={'div'} 
                                            style={{padding: '8px', color: 'red', maxWidth: '100%', overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'nowrap',}}
                                            id="data-request-gln-business-partner-heading">
                                            {t('markantDataRequestModal.GLNBusinessPartner')}*
                                        </div>
                                    </Grid>
                                    <Grid size={1} style={{ backgroundColor: palette.text.veryLight, marginRight: '8px'}}>
                                        <div key={'div'} 
                                            style={{padding: '8px', maxWidth: '100%', overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'pre-line'}}
                                            id="data-request-tax-number-heading">
                                            {t('markantDataRequestModal.taxNumber')}
                                        </div>
                                    </Grid>
                                    <Grid size={2} style={{ backgroundColor: palette.text.veryLight, marginRight: '8px'}}>
                                        <div key={'div'} 
                                            style={{padding: '8px', maxWidth: '100%', overflow: 'hidden', textOverflow: 'ellipsis'}}
                                            id="data-request-business-partner-name-heading">
                                            {t('markantDataRequestModal.businessPartnerName')}
                                        </div>
                                    </Grid>
                                    <Grid size={2} style={{ backgroundColor: palette.text.veryLight, marginRight: '8px'}}>
                                        <div key={'div'} 
                                            style={{padding: '8px', color: 'red', maxWidth: '100%', overflow: 'hidden', textOverflow: 'ellipsis'}}
                                            id="data-request-gtin-basis-product-piece-heading">
                                            {t('markantDataRequestModal.GTINBasisProductPiece')}
                                        </div>
                                    </Grid>
                                    <Grid size={2} style={{ backgroundColor: palette.text.veryLight, marginRight: '8px'}}>
                                        <div key={'div'} 
                                            style={{ padding: '8px', maxWidth: '100%',overflow: 'hidden',textOverflow: 'ellipsis'}}
                                            id="data-request-product-description-heading">
                                            {t('markantDataRequestModal.productDescription')}
                                        </div>
                                    </Grid>
                                    <Grid size={1} style={{ backgroundColor: palette.text.veryLight, marginRight: '8px'}}>
                                        <div key={'div'} 
                                            style={{padding: '8px', maxWidth: '100%', overflow: 'hidden', textOverflow: 'ellipsis'}}
                                            id="data-request-article-type-heading">
                                            {t('markantDataRequestModal.articleType')}
                                        </div>
                                    </Grid>
                                    <Grid size={1} style={{ backgroundColor: palette.text.veryLight, marginRight: '8px', maxWidth: '9.333333%', flexBasis: '9.333333%'}}>
                                        <div key={'div'} 
                                            style={{ padding: '8px', color: 'red', maxWidth: '100%', overflow: 'hidden', textOverflow: 'ellipsis'}}
                                            id="data-request-ekg-heading">
                                            EKG*
                                        </div>
                                    </Grid>
                                    <Grid size={1}></Grid>
                                </Grid>
                            </div>
                            {this.state.itemDetails.map((value, i) => {
                                return <div style={{marginTop: '8px'}} key={i}>
                                    <Grid container spacing={1} style={{display: 'flex', flexWrap: 'nowrap' }}>
                                        <Grid size={2} style={{ marginRight: '8px'}}>
                                            <div key={i}>
                                                <Input name="gln" id={"data-request-gln-input"+i} fullWidth
                                                    value={this.state.itemDetails[i].gln}
                                                    onChange={this.handleFormChange(i)}
                                                />
                                            </div>
                                        </Grid>
                                        <Grid size={1} style={{ marginRight: '8px'}}>
                                            <div key={i}>
                                                <Input  name="tax_number" id={"data-request-tax-number-input"} fullWidth
                                                    value={this.state.itemDetails[i].tax_number}
                                                    onChange={this.handleFormChange(i)}
                                                />
                                            </div>
                                        </Grid>
                                        <Grid size={2} style={{ marginRight: '8px'}}>
                                            <div key={i}>
                                                <Input name="partner_name" id={"data-request-partner-name-input"} fullWidth
                                                    value={this.state.itemDetails[i].partner_name}
                                                    onChange={this.handleFormChange(i)}
                                                />
                                            </div>
                                        </Grid>
                                        <Grid size={2} style={{ marginRight: '8px'}}>
                                            <div key={i}>
                                                <Input name="gtin" id={"data-request-gtin-input"+i} fullWidth
                                                    value={this.state.itemDetails[i].gtin}
                                                    onChange={this.handleFormChange(i)}
                                                />
                                            </div>
                                        </Grid>
                                        <Grid size={2} style={{ marginRight: '8px'}}>
                                            <div key={i}>
                                                <Input name="description" id={"data-request-description-input"+i} fullWidth
                                                    value={this.state.itemDetails[i].description}
                                                    onChange={this.handleFormChange(i)}
                                                />
                                            </div>
                                        </Grid>
                                        <Grid size={1} style={{ marginRight: '8px'}}>
                                            <div key={i}>
                                                <Input name="article_type" id={"data-request-article-type-input"+i} fullWidth
                                                    value={this.state.itemDetails[i].article_type}
                                                    onChange={this.handleFormChange(i)}
                                                />
                                            </div>
                                        </Grid>
                                        <Grid size={1} style={{ marginRight: '8px', maxWidth: '9.333333%', flexBasis: '9.333333%'}} id={"data-request-ekg-drop-down-list-"+i}>
                                            <div key={i}>
                                                <Autocomplete
                                                    id={"data-request-ekg-drop-down-list"}
                                                    size="small"
                                                    value={this.state.itemDetails[i].ekg}
                                                    onChange={this.handleFormChange(i)}
                                                    options={this.state.countryEkgCodeList}
                                                    renderInput={(params) => (
                                                        <TextField {...params} variant="standard"  />
                                                    )}
                                                />
                                            </div>
                                        </Grid>
                                        <div key={i} size={1} style={{ paddingTop:'14px' }}>
                                            <DeleteIcon
                                                style={{ color: red[500], cursor: 'pointer' }}
                                                onClick={this.handleRemoveSpecificRow(i)}
                                                id={"data-request-delete-item-button"+i}
                                            />
                                        </div>
                                    </Grid>
                                </div>
                            })}
                            <div style={{marginTop: '16px'}}>
                                <button onClick={this.handleAddItem} className="btn btn-sm" id="data-request-add-item-button">
                                    {t('markantDataRequestModal.addItem')}
                                </button>
                            </div>
                        </TabPanel>
                        <TabPanel value={this.state.valueTab} index={1}>
                            <ArticlePassRequest activeTab={this.state.valueTab} appContext={this.context} t={t}/>
                        </TabPanel>
                        <TabPanel value={this.state.valueTab} index={2}>
                            <ArticlePassExportList activeTab={this.state.valueTab} appContext={this.context} t={t}/>
                        </TabPanel>
                    </Box>
                </div>
            </Modal>
        );
    }
}

function TabPanel(props) {
    const { children, value, index, ...other } = props;
  
    return (
      <div
        role="tabpanel"
        hidden={value !== index}
        id={`simple-tabpanel-${index}`}
        aria-labelledby={`simple-tab-${index}`}
        {...other}
      >
        {value === index && (
          <Box p={3}>{children}</Box>
        )}
      </div>
    );
}

export default withTranslation()(DataRequestModal);