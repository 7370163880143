export function clearBrowserCache(currentVersion){
    const storedVersion = localStorage.getItem('app_version');

    if (storedVersion !== currentVersion) {
     
        // Clear local storage
        localStorage.removeItem('currentSettings');
        localStorage.removeItem('user');
        localStorage.removeItem('saved');

        // Clear local cookies
        clearCookie('session');
        clearCookie('user_mail');
        clearCookie('user_role');
        clearCookie('is_message_download_allowed');

        // Clear caches if version has changed
        if ('caches' in window) {
            caches.keys().then((names) => {
                names.forEach((name) => caches.delete(name));
            });
        }
  
        // Update version in localStorage
        localStorage.setItem('app_version', currentVersion);
    
        // Reload the app to load the latest resources
        window.location.reload(true);
    }

}
  
function clearCookie(cookieName) {
    document.cookie = `${cookieName}=; expires=Thu, 01 Jan 1970 00:00:00 GMT; path=/`;
}