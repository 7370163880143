/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from 'react';
import { saveAs } from "file-saver";
import Alert from '@mui/material/Alert';
import { DataGrid } from '@mui/x-data-grid';
import Tooltip from '@mui/material/Tooltip';
import {palette} from '../../assets/Palette.jsx';
import SearchFilter from "./SearchFilter.jsx";
import Snackbar from '@mui/material/Snackbar';
import IconButton from '@mui/material/IconButton';
import GetAppIcon from '@mui/icons-material/GetApp';
import { translateKey } from '../../common/helpers/Common.js';
import CircularProgress from '@mui/material/CircularProgress';
import { getCountryNameWithCode } from '../../common/helpers/CountryCodeHelper.js';
import{ getCurrentLanguage, getNumCountryCode, getCookie} from '../../common/helpers/Common.js';

const ArticlePassExportList = ({appContext, t}) => {
  const language = getCurrentLanguage(process.env.REACT_APP_SOURCE)

  const [rows, setRows] = useState([]);
  const [pageSize, setPageSize] = useState(10);
  const [page, setPage] = useState(0);
  const [rowCount, setRowCount] = useState(0);
  const [sortModel, setSortModel] = useState([{ field: 'createdAt', sort: 'asc' }]);
	const [loading, setLoading] = useState(false);
	const [isSearching, setIsSearching] = useState(false);
	const [targetMarketSearch, setTargetMarketSearch] = useState('');
	const [ekgSearch, setEkgSearch] = useState('');
	const [gtinSearch, setGtinSearch] = useState('');
	const [glnSearch, setGlnSearch] = useState('');
	const [downloadErrorSnackBarMessage, setDownloadErrorSnackBarMessage] = useState('');
	const [downloadErrorSnackBar, setDownloadErrorSnackBar] = useState(false);
	const [validTargetMarkets, setValidTargetMarkets] = useState([]);

  useEffect(() => {
    let countryCode = getCookie('country_code')
    countryCode = getNumCountryCode(countryCode)
    countryCode = (countryCode === '') ? 'all' : countryCode
    setTargetMarketSearch(countryCode)
    getValidTargetMarkets()
  }, []);

  useEffect(() => {
    getArticlePassExportList(true);
  }, [targetMarketSearch, page, pageSize, sortModel]);

  const handleTargetMarketSearch = (targetMarket) => {
    setTargetMarketSearch(targetMarket)
  }

  const handleEkgSearch = (ekg) => {
    setEkgSearch(ekg)
  }

  const handleGtinSearch = (gtin) => {
    setGtinSearch(gtin)
  }

  const handleGlnSearch = (gln) => {
    setGlnSearch(gln)
  }

  const getArticlePassExportListWithFilter = () => {
    setPage(0)
    getArticlePassExportList(true)
  }

  const handleOnChange = (event) => {
    let fieldName = event.target.name;
    let value = event.target.value;

    switch (fieldName) {
      case 'ekgSearch':
        setEkgSearch(value);
        break;
      case 'gtinSearch':
        setGtinSearch(value);
        break;
      case 'glnSearch':
        setGlnSearch(value);
        break;
      case 'targetMarketSearch':
        setTargetMarketSearch(value);
        break;
      default:
        break;
    }
  }

  const updateRowData = (data) => {
    return data.map(row => ({
      ...row,
      showDownloadButton: true, 
      showLoader: false
    }));
  };

  const displayDownloadButton = (recordId, status) => {
    let data = rows.map((record, index) =>
      record.id === recordId ? { ...record, showDownloadButton: status } : record
    )
    setRows(data)
  }

  const renderStatus = (status) => {
    const prefix = 'markantDataRequestModal'
    switch (status) {
      case 'deleted':
        return translateKey('deleted', prefix)
      case 'found':
        return translateKey('found', prefix)
      case 'init':
        return translateKey('init', prefix)
      case 'not_a_base_unit':
        return translateKey('notABaseUnit', prefix) 
      default:
        return status
    }
  }

  const handleSnackBarClose = (event, reason) => {
    if (reason === 'clickaway') {
        return;
    }
    setDownloadErrorSnackBar(false)
  };

  const getArticlePassExportList = async (useFilters = false) => {
		setLoading(true); 
    const sortColumn = sortModel.length ? sortModel[0].field : 'createdAt';
    const sortDirection = sortModel.length ? sortModel[0].sort : 'asc';

    try {
      let targetMarket = (targetMarketSearch !== 'all') ? targetMarketSearch : ''
      let params = {
        ekg: ekgSearch,
        gtin: gtinSearch,
        targetMarket: targetMarket,
        gln: glnSearch,
        pageNumber: page + 1,
        perPage: pageSize,
        sortColumn: sortColumn,
        sortDirection: sortDirection
      }

      const response = await appContext.apiService.getArticlePassExportList(useFilters, params);
      if (response.status === 200) {
        response.json().then(res => {
          const data = updateRowData(res.data);
          setRows(data);
          setRowCount(res.total);
          setIsSearching(false)
          console.log(rows)
        });
      }
    } catch (error) {
      console.error('Error fetching data:', error);
      setIsSearching(false)
    } finally {
      setLoading(false); 
    }
  };

  const downloadApassExport = async (apassExportId, filename, gtin) => {
    const errorMessage = `Error while downloading GTIN: ${gtin}`
    const file_name = filename.split('/').pop()

    displayDownloadButton(apassExportId, false)
    await appContext.apiService.downloadApassExport(apassExportId)
    .then(res => {
      if (res.ok) {
        return res.blob()
      } else {
        displayDownloadButton(apassExportId, true)

        setDownloadErrorSnackBarMessage(errorMessage)
        setDownloadErrorSnackBar(true)
        res.json().then(res => {
          console.log("Error in fetching article pass request");
          console.log(res);
        })
      }
    }).then(blob => {
      if (blob !== undefined) {
        if (blob instanceof Blob) {
          saveAs(blob, file_name);
          displayDownloadButton(apassExportId, true)
        } else {
          displayDownloadButton(apassExportId, true)

          setDownloadErrorSnackBarMessage(errorMessage)
          setDownloadErrorSnackBar(true)
          console.log("Not a valid content");
        }
      }
    });
  }

  const getValidTargetMarkets = async () => {
    await appContext.apiService.getValidTargetMarketsForMarkant()
      .then(res => {
        if (res.ok) {
          res.json().then(res => {
            setValidTargetMarkets( res.data);
          });
        } else {
          res.json().then(res => {
            console.log(res);
          })
        }
      });
  }
	
	const columns = [
    { field: 'gln', headerName: 'GLN', flex: 0.12, filterable: false, headerClassName: 'header-bold' },
    { field: 'ekg', headerName: 'Ekg', flex: 0.09, filterable: false, headerClassName: 'header-bold' },
    { 
      field: 'targetMarket', 
      headerName: 'Target Market', 
      flex: 0.13, 
      filterable: false,
      headerClassName: 'header-bold',
      renderCell: (params) => (
        <span>{getCountryNameWithCode(params.row.targetMarket, language)}</span>
      )
    },
    { field: 'gtin', headerName: 'GTIN', flex: 0.10, filterable: false, headerClassName: 'header-bold' },
    { 
      field: 'status', 
      headerName: 'Status', 
      flex: 0.07, 
      filterable: false,
      headerClassName: 'header-bold',
      renderCell: (params) => (
        <span>{renderStatus(params.row.status)}</span>
      )
    },
    { 
      field: 'as2Status', 
      headerName: 'AS2 Status', 
      flex: 0.08, 
      filterable: false,
      headerClassName: 'header-bold',
      renderCell: (params) => (
        ( params.row.as2Status ?
            <span>{t("markantDataRequestModal.as2Status."+(params.row.as2Status))}</span>
          : ''
        )
      )
    },
    { 
			field: 'createdAt', 
			headerName: 'Created At', 
			flex: 0.12, 
			filterable: false,
      headerClassName: 'header-bold',
			renderCell: (params) => (
				<span>{new Date(params.row.createdAt).toLocaleString()}</span>
			)
		},
    { 
			field: 'updatedAt', 
			headerName: 'Updated At', 
			flex: 0.12, 
			filterable: false,
      headerClassName: 'header-bold',
			renderCell: (params) => (
				<span>{new Date(params.row.updatedAt).toLocaleString()}</span>
			)
		 },
    {
      field: 'actions',
      headerName: 'Actions',
      flex: 0.05,
      sortable: false,
      filterable: false,
      disableColumnMenu: true,
      headerClassName: 'header-bold',
      renderCell: (params) => (
        ( params.row.showDownloadButton ?
          (  params.row.filename && params.row.filename.includes('.xlsm') &&
          <Tooltip title="Download">
              <IconButton
                  color="primary"
                  onClick={() => downloadApassExport(params.row.id, params.row.filename, params.row.gtin)}
                  size="large">
                  <GetAppIcon color="primary" style={{cursor: "pointer", marginRight: '8%'}}/>
              </IconButton>
          </Tooltip> )
          :
          <Tooltip title="Download">
            <IconButton size="large">
              <CircularProgress size={20} style={{ color: palette.secondary.main }} thickness={4} />
            </IconButton>
          </Tooltip>
        )
      ),
    },
  ];

  return (
    <div style={{ height: 650, width: '100%'}}>
      <Snackbar open={downloadErrorSnackBar} autoHideDuration={6000}
          anchorOrigin={{ vertical: 'top', horizontal: 'right'}}>
          <Alert severity="error" onClose={handleSnackBarClose} >
              {downloadErrorSnackBarMessage}
          </Alert>
      </Snackbar>
      <SearchFilter isSearching = {isSearching} 
        handleTargetMarketSearch = {handleTargetMarketSearch} 
        handleEkgSearch = {handleEkgSearch} 
        handleGtinSearch = {handleGtinSearch} 
        handleGlnSearch = {handleGlnSearch} 
        targetMarketSearch = {targetMarketSearch} 
        gtinSearch = {gtinSearch} 
        glnSearch = {glnSearch} 
        ekgSearch = {ekgSearch} 
        validTargetMarkets = {validTargetMarkets}  
        submitFunction = {getArticlePassExportListWithFilter} 
        handleOnChange = {handleOnChange}
      />
      <div style={{paddingTop: "2rem"}}></div>
      <DataGrid
        disableColumnSelector
        loading={loading}
        rows={rows}
        columns={columns}
        paginationModel={{ page, pageSize }}
        pagination
        onPaginationModelChange={(newPaginationModel) => {
          setPage(newPaginationModel.page);
          setPageSize(newPaginationModel.pageSize);
        }}
        paginationMode="server"
        rowCount={rowCount}
        sortingMode="server"
        sortModel={sortModel}
        onSortModelChange={(newSortModel) => setSortModel(newSortModel)}
        pageSizeOptions={[5, 10, 25]}
        disableRowSelectionOnClick
        sx={{
          '& .MuiDataGrid-columnHeaders': {
            width: '99% !important',
          },
          '& .MuiCircularProgress-root': {
            color: palette.secondary.main,
          },
          '& .MuiDataGrid-columnHeaderTitle': {
            fontWeight: 'bold',
          },
        }}
      />
    </div>
  );
};

export default ArticlePassExportList;
