import ViewRenderer from './base/ViewRenderer';
import React from "react";
import {palette} from '../../assets/Palette';
import MoreButton from '../../layouts/MoreButton.jsx';
import {getDataSourceLabel} from '../../common/helpers/Common'
import No_image from '../../assets/img/No_Image.png';

export default class MigrosViewRenderer extends ViewRenderer {

    renderTable(records, generateMigrosXMLContent, toggleGPCModalSearch, handleSnackBarOpen, downloadOriginalMessage) {
        let table = []
        if (records !== null) {
          for (let i = 0; i < records.length; i++) {
            let children = []
            let brickDescriptionDe = ''
            var record = records[i]
            let updatedDate = record["updatedTimestamp"].replace('T', ' ')
            let sourceLabel = getDataSourceLabel(record["source"])

            updatedDate = updatedDate.split('.')[0]
            if ('gpcCategoryDesc' in record) {
              brickDescriptionDe = record['gpcCategoryDesc']
            }
            if ('thumbnail' in record && record['thumbnail'].length > 0) {
              let image_tag = <img alt="thumbnail" style={{ maxWidth: '100%', maxHeight: '100%' }} src={No_image} />
              if (record['thumbnail']) {
                image_tag = <img alt="thumbnail" style={{ maxWidth: '100%', maxHeight: '100%' }} src={'data:image/png;base64,' + record['thumbnail']} />
              }
              children.push(<td key={i + ',1'} onClick={() => generateMigrosXMLContent(i)} align='center'><div style={{ margin: 'auto', width: '75px', height: '75px' }}>{image_tag}</div></td>)
            } else {
              children.push(<td key={i + ',1'} onClick={() => generateMigrosXMLContent(i)}><div style={{ margin: 'auto', width: '75px', height: '75px' }}><img alt="thumbnail" style={{ maxWidth: '100%', maxHeight: '100%' }} src={No_image} /></div></td>)
            }
            children.push(<td key={i + ',2'} onClick={() => generateMigrosXMLContent(i)}>{record["gtin"]}</td>)
            children.push(<td key={i + ',3'} onClick={() => generateMigrosXMLContent(i)}>{record["brandName"]}</td>)
            children.push(<td key={i + ',4'} onClick={() => generateMigrosXMLContent(i)}>{record["productDescription"]}</td>)
            children.push(<td key={i + ',5'} onClick={() => generateMigrosXMLContent(i)}>{record["productName"]}</td>)
            if ('gpcCategoryCode' in record) {
              children.push(<td key={i + ',6'} ><div style={{ color: palette.primary.main, cursor: 'pointer' }} onClick={() => toggleGPCModalSearch(i)}>{record["gpcCategoryCode"] + ' - ' + brickDescriptionDe}</div></td>)
            } else {
              children.push(<td key={i + ',6'} ></td>)
            }
            if (!('manufacturer' in record) && 'informationProviderOfTradeItem' in record) {
              children.push(<td key={i + ',7'} onClick={() => generateMigrosXMLContent(i)}>{record["informationProviderOfTradeItem"]}</td>)
            } else {
              children.push(<td key={i + ',7'} onClick={() => generateMigrosXMLContent(i)}>{record["manufacturer"]}</td>)
            }
            children.push(<td key={i + ',8'} onClick={() => generateMigrosXMLContent(i)}>{record["unitDescriptor"]}</td>)
            children.push(<td key={i + ',9'} onClick={() => generateMigrosXMLContent(i)}>{record["countryCodeAlpha2"] + ' (' + record["targetMarket"] + ')'}</td>)
            children.push(<td key={i + ',10'} onClick={() => generateMigrosXMLContent(i)}>{updatedDate}</td>)
            children.push(<td key={i + ',11'} onClick={() => generateMigrosXMLContent(i)}>{sourceLabel}</td>)
            children.push(<td key={i + ',12'}> 
            <MoreButton 
              product={record}
              recordIndex={i}
              handleSnackBarOpen={handleSnackBarOpen}
              downloadOriginalMessage={downloadOriginalMessage}
            /> </td>)
            table.push(<tr key={i} style={{ cursor: 'pointer' }}>{children}</tr>)
          }
        }
        return table
    }
}
