
import { Button, IconButton, LinearProgress, TextField, Tooltip, Typography } from '@mui/material';
import withStyles from '@mui/styles/withStyles';
import CloseRoundedIcon from '@mui/icons-material/CloseRounded';
import DescriptionIcon from '@mui/icons-material/Description';
import HelpIcon from '@mui/icons-material/Help';
import { saveAs } from "file-saver";
import React from "react";
import { withTranslation } from 'react-i18next';
import Modal from 'react-modal';
import { Form, Label } from "reactstrap";
import FormControlLabel from '@mui/material/FormControlLabel';
import Switch from '@mui/material/Switch';
import { ThemeProvider, StyledEngineProvider } from "@mui/material/styles";
import { CustomerContext } from "../../common/CustomerContext.js";
import {palette} from '../../assets/Palette';

const exportModalStyle = {
    content: {
        top: '17%',
        left: '50%',
        right: '72%',
        marginRight: '-50%',
        transform: 'translate(-50%, -17%)',
        overlay: { zIndex: 1000 }
    }
};


const HtmlTooltip = withStyles((theme) => ({
    tooltip: {
        backgroundColor: palette.text.dark,
        color: 'rgba(0, 0, 0, 0.87)',
        maxWidth: 220,
        fontSize: theme.typography.pxToRem(12),
        border: '1px solid #dadde9',
    },
}))(Tooltip);


class ExportModal extends React.Component {

    static contextType = CustomerContext;
    constructor(props) {
        super(props);
        this.state = {
            format: 'excel',
            disableButton: false,
            inputValue: "",
            error: false,
            helperText: "",
            targetMarketSwitchesExportModal: props.targetMarketSwitchesExportModal
        }
        this.handleFormatChange = this.handleFormatChange.bind(this);
        this.exportData = this.exportData.bind(this);
        this.resetModal = this.resetModal.bind(this);
        this.generateTargetMarketSwitchesExportModal = this.generateTargetMarketSwitchesExportModal.bind(this);
        this.handleTargetMarketExportModal = this.handleTargetMarketExportModal.bind(this);
        this.downloadGdsnJson = this.downloadGdsnJson.bind(this);
        
    }
        
    handleFormatChange(event) {
        this.setState({
            format: event.target.value
        });
    }
    
    static getDerivedStateFromProps(props, state) {
        return { targetMarketSwitchesExportModal: props.targetMarketSwitchesExportModal };
    }

    getActiveTargetMarketList() {
        let tm_list = []
        if (this.state.targetMarketSwitchesExportModal.length > 0) {
            for (let i = 0; i < this.state.targetMarketSwitchesExportModal.length; i++) {
                let tm_list_keys = []
                tm_list_keys = Object.keys(this.state.targetMarketSwitchesExportModal[i])
                if(tm_list_keys.length > 0)
                {
                    let tm_list_key = tm_list_keys[0]
                    let tm_list_value = this.state.targetMarketSwitchesExportModal[i][tm_list_keys[0]]
                    if(tm_list_value) {
                        tm_list.push(tm_list_key)
                    }
                }
            }
        }
        console.log('list:',tm_list)
        return tm_list
    }

    exportData(event) {
        let tm_list = this.getActiveTargetMarketList()
        let gtinList = this.state.inputValue.split(/\s+/);
        let fileName = ''
        console.log("GTIN List: ", gtinList)
        this.setState({
            disableButton: true,
            error: false,
            helperText: ""
        });
        gtinList = gtinList.filter(item => item);
        if (gtinList.length === 0) {
            this.setState({
                disableButton: false,
                error: true,
                helperText: "GTIN List is empty"
            });
            return
        }
        if (gtinList.length > 100) {
            this.setState({
                disableButton: false,
                error: true,
                helperText: "Maximum 100 GTINs allowed"
            });
            return
        }
        this.props.apiService.exportData(gtinList,tm_list,this.props.languege)
            .then(res => {
                if (res.ok) {
                    let contentDisposition = res.headers.get('content-disposition').split(';')[1]
                    fileName = contentDisposition.split('=')[1]
                    return res.blob();
                } else {
                    this.setState({
                        disableButton: false,
                        error: false,
                        helperText: ""
                    });
                    return res.json()
                }
            }).then(blob => {
                if (blob !== undefined) {
                    if (blob instanceof Blob) {
                        saveAs(blob, fileName);
                        this.setState({
                            disableButton: false
                        });
                    } else if ('responseDescription' in blob) {
                        alert(blob['responseDescription'])
                    }
                }
            }).catch(err => console.error(err));
    }

    downloadGdsnJson(event){

        let gtin = this.state.inputValue
        this.context.apiService.getProductByGtin(gtin)
            .then( res => res.json() )
            .then( res => {
                console.log(res)
                let content = JSON.stringify(res);
                var blob = new Blob([content], { type: 'text/json' });
                var url = URL.createObjectURL(blob);
                var tempLink = document.createElement('a');
                tempLink.href = url;
                tempLink.setAttribute('download', gtin + '.json');
                tempLink.click();
                URL.revokeObjectURL(url)
            })
    }

    resetModal(event) {
        this.setState({
            inputValue: "",
            disableButton: false,
            error: false,
            helperText: ""
        });
    }

    onTextChange(e) {
        this.setState({
            inputValue: e.target.value,
            disableButton: false
        })
    }

    generateTargetMarketSwitchesExportModal(t) {
        let targetMarketSwitches = []
        if (this.props.targetMarketSwitchesExportModal.length > 0) {
            for (let i = 0; i < this.props.targetMarketsExportModal.length; i++) {
                targetMarketSwitches.push(<FormControlLabel key={'form ' + i} control={<Switch size="small" checked={this.props.targetMarketSwitchesExportModal[i][this.props.targetMarketsExportModal[i]['key']]} value={this.props.targetMarketsExportModal[i]['key']} name={'export' + this.props.targetMarketsExportModal[i]['key']} color="primary"/>} label={this.props.targetMarketsExportModal[i]['countryCodeAlpha2']} labelPlacement='end' onChange={this.handleTargetMarketExportModal} />)
            }
            } else {
            targetMarketSwitches.push(<Label key='form label'>{t('searchResults.loadingTargetMarkets')}</Label>)
            }
            return targetMarketSwitches
        }
    
    handleTargetMarketExportModal(event) {
        let updateTargetMarketSwitches = this.props.targetMarketSwitchesExportModal
        for (let i = 0; i < updateTargetMarketSwitches.length; i++) {
            if (event.target.value in updateTargetMarketSwitches[i]) {
            updateTargetMarketSwitches[i][event.target.value] = !updateTargetMarketSwitches[i][event.target.value]
            }
        }
        this.setState({ targetMarketSwitchesExportModal: updateTargetMarketSwitches })
        }

    render() {
        const { t } = this.props;
        return (
            (<Modal
                isOpen={this.props.isExportModalDisplayed}
                toggle={this.props.toggleExportModal}
                style={exportModalStyle}
                ariaHideApp={false}
            >
                <div style={{ display: 'flex', flexDirection: 'row', position: 'fixed', right: '4%', top: '5%' }}>
                    <Tooltip title={t('exportModal.close')}>
                        <IconButton
                            aria-label="Close"
                            className="close"
                            data-dismiss="modal"
                            type="button"
                            onClick={() => { this.props.toggleExportModal(); this.resetModal(); }}
                            style={{ outline: 'none' }}
                            size="large">
                            <CloseRoundedIcon style={{ color: palette.warning.close }} />
                        </IconButton>
                    </Tooltip>
                </div>
                <div>
                    <h2 key={'h1'}>{t('exportModal.excelExport')}</h2>
                    <Form key="form">
                        <h6 key={'h6exportFormat'}>{t('exportModal.exportFormat')}</h6>
                        <div key="radioDiv" style={{ display: 'flex', flexWrap: 'wrap' }}>
                            <input key="radioExcel" style={{accentColor: palette.primary.main}} type="radio" value="excel" name="excel" checked={this.state.format === "excel"} onChange={this.handleFormatChange} />
                            <Label key="labelExcel" style={{marginTop: '1.5%', marginLeft: '2%', marginRight: '4%' }}>{t('exportModal.excel')}</Label>
                            <input key="radioXML" style={{accentColor: palette.primary.main}} type="radio" disabled value="xml" name="xml" checked={this.state.format === "xml"} onChange={this.handleFormatChange} />
                            <Label key="labelXML" style={{ marginTop: '1.5%', marginLeft: '2%', marginRight: '4%' }}>{t('exportModal.xml')}</Label>
                            <input key="radioJSON" style={{accentColor: palette.primary.main}} type="radio" disabled value="json" name="json" checked={this.state.format === "json"} onChange={this.handleFormatChange} />
                            <Label key="labelJSON" style={{ marginTop: '1.5%', marginLeft: '2%', marginRight: '4%' }}>{t('exportModal.json')}</Label>
                            {
                            process.env.REACT_APP_SOURCE === 'radeberger' &&
                                <input key="radioPdf" style={{accentColor: palette.primary.main}} type="radio" disabled value="pdf" name="pdf" checked={this.state.format === "pdf"} onChange={this.handleFormatChange} />
                            }
                            {
                            process.env.REACT_APP_SOURCE === 'radeberger' &&
                                <Label key="labelPdf" style={{ marginTop: '1.5%', marginLeft: '2%', marginRight: '4%' }}>{t('exportModal.pdf')}</Label>
                            }
                        </div>
                        <h6 key={'h6targetMarket'}>{t('exportModal.targetMarket')}</h6>
                        <StyledEngineProvider injectFirst>
                            <ThemeProvider theme={palette}>
                                {this.generateTargetMarketSwitchesExportModal(t)}
                            </ThemeProvider>
                        </StyledEngineProvider>
                    </Form>
                    <div key='textAreaDiv' style={{ display: 'block', overflow: 'auto', position: 'relative', height: '40vh', width: '30vh' }}>
                        {
                            process.env.REACT_APP_SOURCE === 'schwarz' ? <TextField key="gtinList" error={this.state.error} id="gtinList" label="GTIN List" placeholder="GTIN" multiline style={{ width: '100%' }} onChange={this.onTextChange.bind(this)} helperText={this.state.helperText} />
                                : <TextField key="gtinList" error={this.state.error} id="gtinList" label="GTIN" placeholder="GTIN" style={{ width: '100%' }} onChange={this.onTextChange.bind(this)} helperText={this.state.helperText} />
                        }
                    </div>
                    <div style={{ display: 'flex', flexDirection: 'row' }}>
                        {process.env.REACT_APP_SOURCE === 'schwarz' ? <Label key='excelExport label' style={{ color: palette.text.main, fontWeight: 'bold', fontSize: '0.8rem', marginTop: '2%' }}>{t('exportModal.excelExportModalHelperText')}</Label>
                            : <Label key='excelExport label' style={{ color: palette.text.main, fontWeight: 'bold', fontSize: '0.8rem', marginTop: '2%' }}>{t('exportModal.gdsnExportModalHelperText')}</Label>}
                        {
                            process.env.REACT_APP_SOURCE === 'schwarz' ?
                                <HtmlTooltip title={
                                    <React.Fragment>
                                        <Typography style={{ color: "#3f51b5" }}>{t('exportModal.example')}</Typography>
                                        1234567891234 1234567891235 1234567891236
                                        <Typography style={{ color: "#3f51b5" }}>{t('exportModal.or')}</Typography>
                                        1234567891234<br />1234567891235<br />1234567891236
                                    </React.Fragment>
                                } arrow style={{ outline: 'none' }}>
                                    <IconButton aria-label="helper" style={{ outline: 'none' }} size="large">
                                        <HelpIcon color="primary" />
                                    </IconButton>
                                </HtmlTooltip>
                                : null
                        }
                    </div>
                    <Button key="exportButton" disabled={this.state.disableButton} variant="contained" color="primary" startIcon={<DescriptionIcon />} style={{ outline: 'none', marginTop: '10%' }} onClick={this.exportData}>{t('exportModal.export')}</Button>
                    {this.state.disableButton ? <LinearProgress key="linearProgress1" style={{ marginTop: '5%' }} /> : null}
                    {this.state.error ? <LinearProgress key="linearProgress2" color="secondary" variant="determinate" style={{ marginTop: '5%' }} /> : null}
                    <Button key="downloadGdsnJson" variant="contained" color="primary" startIcon={<DescriptionIcon />} style={{ outline: 'none', marginTop: '10%', marginLeft: '10%' }} onClick={this.downloadGdsnJson}>{t('exportModal.downloadGdsnJson')}</Button>
                </div>
            </Modal>)
        );
    }
}

export default withTranslation()(ExportModal);