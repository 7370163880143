/* eslint-disable no-useless-escape */

export function isGtinValid(gtin) {
    return (gtin.length === 0) ? true : isDigitOnly(gtin) && (gtin.length === 14);
}

export function isGlnValid(gln) {
    return (gln.length === 0) ? true : isDigitOnly(gln) && (gln.length === 13);
}
export function isSearchTermValid(searchTerm) {
    return isAlphabetOnly(searchTerm) &&  (searchTerm.length !== 0);
}
export function isProductIdValid(productId) {
    return isAlphabetOnly(productId) && (productId.length === 5);
}

export function isEmailValid(emailId) {
    return (emailId.length === 0) ? true : isEmailId(emailId);
}
export function isGpcValid(gpc) {
    return (gpc.length === 0) ? true : isDigitOnly(gpc);
}

// @@TODO 
// Implement this function as per the requirment in the feature
export function isEkgValid(gpc) {
    return true
}

export function isDigitOnly(string) {
    return /^\d+$/.test(string);
}
export function isEmailId(string) {
    let re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(string);
}
export function isAlphabetOnly(string) {
    return /^[a-zA-Z\s]*$/.test(string);
}
export function isTargetMarketSubscription(targerMarket, gtin, gln, gpc) {
    return targerMarket.length !== 0 &&
        (gtin.length === 0 && gln.length === 0 && gpc.length === 0);
}

export function isSubscriptionCombinationCorrect(targerMarket, gtin, gln, gpc) {
    if (targerMarket.length !== 0 && gln.length !== 0 && gtin.length === 0 && gpc.length === 0) {
        return true;
    } else if (targerMarket.length !== 0 && gln.length !== 0 && gtin.length !== 0 && gpc.length === 0) {
        return true;
    } else if (targerMarket.length !== 0 && gln.length === 0 && gtin.length === 0 && gpc.length !== 0) {
        return true;
    } else if (targerMarket.length !== 0 && gln.length !== 0 && gtin.length === 0 && gpc.length !== 0) {
        return true;
    }
    return false;
}

