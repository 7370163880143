import React from "react";
import { Table} from 'reactstrap'
import { withTranslation } from 'react-i18next';
import SearchIcon from '@mui/icons-material/Search';
import AddRoundedIcon from '@mui/icons-material/AddRounded';
import Moment from 'react-moment';
import { getNumberWithSeparator } from '../common/helpers/Common.js';

class TableWithBorders extends React.Component {

    supplierClick = (gln) => {
        this.props.handleSupplierClick(gln);
    }

    supplierWithScopeClick = (gln) => {
        this.props.handleSupplierWithScopeClick(gln);
    }

    generateTable() {
        const suppliers = this.props.data.dashboardData['suppliersInfo']
        const suppliersHtml = suppliers.map((data, id) => {
            return <tr key={data['gln']}>
                <td>{data['partyName']}</td>
                <td>{data['gln']}</td>
                <td>{getNumberWithSeparator(this.props.language,data['nof_items'])}</td>
                <td><Moment format="YYYY-MM-DD HH:MM:SS">{data['updatedAt']}</Moment></td>
                <td>
                    <div class="row">
                        <div class="col-4 pr-3 mr-3" style={{cursor: 'pointer'}} onClick= {()=> this.supplierClick(data['gln'])}>
                            <SearchIcon style={{ fontSize: 30 }}></SearchIcon>
                        </div>
                        <div class="row" style={{cursor: 'pointer'}} onClick={() => this.supplierWithScopeClick(data['gln'])}>
                            <div class="col">
                                <SearchIcon style={{ fontSize: 30 }}></SearchIcon>
                            </div>
                            <div class="col search-icon-with-plus">
                                <AddRoundedIcon></AddRoundedIcon>
                            </div>
                        </div>
                    </div>
                </td>
            </tr>
        });

        return (
            <div class="table-wrapper-scroll-y my-scrollbar" id="style-1">
                <Table striped bordered hover>
                    <thead>
                        <tr>
                            <th>Supplier</th>
                            <th>GLN</th>    
                            <th>#Items</th>
                            <th>Last Updated</th>
                            <th>Show Items</th>
                        </tr>
                    </thead>
                <tbody class="table-scroll">
                        {suppliersHtml}
                </tbody>
                </Table>
            </div>
            
        );
    }

    render() {
        return this.generateTable()
    }
}

export default withTranslation()(TableWithBorders)
