import React from 'react';
import { Pagination } from '@mui/material';

function SearchPagination({ count, page, recordsPerPage, onChange }) {
  return (
    <>
      {count > recordsPerPage && (
        <Pagination
          count={Math.ceil(count / recordsPerPage)}
          page={page}
          onChange={onChange}
          variant="outlined"
          color="secondary"
        />
      )}
    </>
  );
}

export default SearchPagination;